.client-texts-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  color: #fff;
  width: 95%;
  margin: 1% auto;
  padding: 1%;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 15px #000000;
  background: #1a3a75c5;
  background: linear-gradient(to right bottom, #3c1448ec, #1a3a75eb);
  overflow: hidden;
}

.client-texts-hdr-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.client-texts-text-and-num-box {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.client-texts-mes-box {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 70dvh;
  overflow: auto;
  scrollbar-width: none;
  /* background-color: #4b4b4b;
  border: 1px solid #fff; */
  border-radius: 4px;
  padding: 0 14px;
}

.client-texts-mes-box-out {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.client-texts-mes-box-inc {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.client-texts-mes-item {
  display: flex;
  max-width: 80%;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 3px 10px;
  margin: 2px;
  border-radius: 10px;
}
.client-texts-mes-item-out {
  background-color: #05ac05;
}

.client-texts-mes-item-inc {
  background-color: #4e4e4f;
}

.client-texts-mes-item-date {
  font-size: 9px;
}

.client-texts-messenger-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

/* messages hover scroll btns */
.client-texts-hvr-scroll {
  position: absolute;
  left: 3%;
  z-index: 14;
  width: 93%;
  height: 30px;
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0z" fill="none"/><path stroke="white" fill="white" d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"/></svg>')
      14 10,
    default;
  border: none;
  background: none;
}

.client-texts-hvr-scroll-top {
  top: 0;
}

.client-texts-hvr-scroll-top:hover {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0z" fill="none"/><path stroke="white" fill="white" d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"/></svg>')
      14 10,
    default;
}

/* <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/></svg> */

.client-texts-hvr-scroll-bottom:hover {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0z" fill="none"/><path stroke="white" fill="white" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/></svg>')
      14 10,
    default;
}

.client-texts-hvr-scroll-bottom {
  bottom: 0;
}

/* Scroll Shadow */
.client-texts-shadow-wrapper {
  position: relative;
  border: 1px solid #d0d0d000;
  width: 70%;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
}

.client-texts-shadow {
  position: absolute;
  left: 2%;
  z-index: 10;
  width: 96%;
  height: 30px;
  background-image: red;
  pointer-events: none;
}

.client-texts-shadow--top {
  top: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(47, 20, 72, 0.6) 60%,
    rgba(26, 58, 117, 0) 100%
  );
  /* background-image: (to bottom, rgba(47, 20, 72, 0.925), rgba(26, 58, 117, 0.922)); */
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: #ffffff53;
}

.client-texts-shadow--bottom {
  bottom: 0;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(47, 20, 72, 0.6) 60%,
    rgba(26, 58, 117, 0) 100%
  );
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #ffffff53;
}
/* End shadow controls */

.client-texts-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
}

.client-texts-btn.send-text-dis {
  background: linear-gradient(to right bottom, #555555cb, #c0bfbfd5);
}

@media screen and (max-width: 900px) {
  .client-texts-shadow-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
}
