.shortcuts-page-page {
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.shortcuts-page-container {
  display: flex;
  width: 95%;
  margin: 1% auto;
  padding: 1%;
  flex-direction: column;
  align-items: center;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 30px #000000;
  backdrop-filter: blur(4px);
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
}

.shortcuts-page-item-create-box {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  margin: 3px 0;
}

.shortcuts-page-table-box {
  width: 98%;
  margin: 0 auto 8px;
  border: 1px solid #fff;
}

.shortcuts-page-table-box tr td:first-of-type,
.shortcuts-page-table-box tr th:first-of-type {
  padding-left: 10px;
  padding-top: 4px;
  text-align: start;
}

.shortcuts-page-table-box tr td,
.shortcuts-page-table-box tr th {
  margin: 0 4px;
  text-align: center;
}

.shortcuts-page-table-box tr:nth-child(even) {
  background-color: #5251519c;
  padding: 2px 0;
  border-bottom: 1px solid #fff;
}

.shortcuts-page-table-box tr:nth-child(odd) {
  background-color: #3737379c;
  padding: 2px 0;
  border-bottom: 1px solid #fff;
}

.shortcuts-page-item-actions {
  display: flex;
  justify-content: center;
  text-align: end;
}

.shortcuts-page-action-img-button {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
  color: #fff;
  background: none;
  border: none;
  cursor: inherit;
  padding: 0 1px;
}

.shortcuts-page-new-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98%;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -5px 5px 10px #000000;
  margin: 6px 0 10px;
}

.shortcuts-page-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
}
