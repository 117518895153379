.delete-group-container {
  display: flex;
  max-height: 80vh;
  overflow: auto;
  width: 90%;
  margin: 1%;
  padding: 1%;
  min-height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background-color: #cacaca;
}

.delete-id-input {
  width: 100%;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid #fff;
  font-size: 12px;
  border: 2px solid #fff;
  border-radius: 5px;
  background: linear-gradient(to left top, #18516b, #8f8792);
  box-shadow: 0px 0px 8px #000000;
}

.delete-info-box {
  width: 100%;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid #fff;
  font-size: 12px;
  border: 2px solid #fff;
  border-radius: 5px;
  background: linear-gradient(to left top, #18516b, #8f8792);
  box-shadow: 0px 0px 8px #000000;
}

#delete-bag-button {
  width: fit-content;
  margin: 20px;
  padding: 8px;
  color: #fff;
  border: 4px solid #fff;
  border-radius: 5px;
  background: linear-gradient(to left top, #18516b, #8f8792);
  box-shadow: 0px 0px 20px #000000;
  transition: all 0.5s;
}
#delete-bag-button:hover,
#delete-bag-button:focus {
  padding: 8px 12px;
  color: #3a3a3a;
  background: linear-gradient(to right bottom, #a3b8a5, #ffffff);
}

.delete-none {
  display: none;
}

.delete-over-loader {
  width: 100%;
  height: 100%;
  color: #fff;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #38333380;
}

.delete-img-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete-img {
    width: 20%;
    margin: 3% 1%;
    border: 2px solid #fff;
    border-radius: 4px;
    box-shadow: -3px 3px 8px #000;
    display: flex;
    justify-content: center;
}

.delete-info-ul {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    height: fit-content;
    width: fit-content;
    list-style: none;
    margin: 15px;
    padding: 20px;
    line-height: 1.6rem;
    background: linear-gradient(to right bottom, #18516b, #8f8792);
    border: 3px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #000000;
  }

@media screen and (max-width: 900px) {
    .delete-group-container {
        width: 90%;
    }
  }

  @media screen and (max-width: 600px) {
    .delete-img-box {
        flex-wrap: wrap;
    }
    
    .delete-img {
        width: 45%;
    }
  }