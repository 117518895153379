.user-task-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: space-evenly;
  background-color: #0000007d;
  backdrop-filter: blur(5px);
  animation: user-task-modal-fader 0.2s linear forwards;
}

@keyframes user-task-modal-fader {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.user-task-modal-content {
  position: relative;
  color: #000000;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
  padding: 1%;
  margin: auto 10px;
  z-index: 5;
  max-height: 95vh;
  height: fit-content;
  min-width: 55%;
  width: fit-content;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 30px #000000;
  overflow: auto;
  color: #fff;
}

.user-task-modal-header {
  text-decoration: underline;
  white-space: nowrap;
  padding: 8px 10px;
  background: linear-gradient(to right bottom, #34113fcc, #2a8ebde2);
  color: #fff;
  text-shadow: -6px 6px 12px #000;
  border-radius: 6px;
  border: 1px solid #fff;
  width: fit-content;
  height: fit-content;
}

.user-task-start-button {
  color: #fff;
  z-index: 22;
  background: linear-gradient(to right bottom, #333333a4, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  font-size: inherit;
  font-weight: inherit;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
}

.user-task-modal-close-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: transparent;
  height: fit-content;
}

.user-task-inputs-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

.user-task-inputs-box {
  margin: 5px auto 2px;
  width: 95%;
  display: flex;
  justify-content: space-between;
}

.user-task-associated-client-box {
  width: 45%;
  margin: 3px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
}

.user-task-text-nowrap {
  text-wrap: nowrap;
  white-space: nowrap;
  padding-right: 4px;
}

.user-task-due-date-box {
  width: 45%;
  display: flex;
  justify-content: space-around;
}

.user-task-small-remove-btn {
  color: #fff;
  z-index: 22;
  background: linear-gradient(to right bottom, #333333a4, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  font-size: 10px;
  font-weight: inherit;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
}

.user-task-save-box {
    display: flex;
    width: 95%;
    justify-content: flex-end;
}

.user-task-error-ul {
    margin: 10px auto;
  }

@media screen and (max-width: 600px) {
  .user-task-modal-header {
    font-size: 26px;
    margin: 5px;
    width: 100%;
    text-align: center;
    border-radius: 0px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    border-left: none;
    border-right: none;
    padding: none;
    margin: none;
  }

  .user-task-modal-content {
    width: 95%;
  }

  .user-task-inputs-box {
    margin: 5px auto 2px;
    width: 95%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .user-task-associated-client-box {
    width: 95%;
    margin: 3px 0px 5px;
  }

  .user-task-due-date-box {
    width: 95%;
  }
}
