.user-info-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #0000007d;
  backdrop-filter: blur(3px);
  animation: user-info-modal-fader 0.2s linear forwards;
}

@keyframes user-info-modal-fader {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.user-info-modal-content {
  color: #fff;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
  width: 80%;
  padding: 15px;
  z-index: 15;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 30px #000000;
  max-height: 90vh;
  overflow: auto;
}

.user-info-sec-hdr {
  color: #05f842;
}

.user-info-modal-sec {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: fit-content;
  width: 95%;
  margin: 10px auto;
  padding: 2px 8px;
}

.user-info-modal-sec.center {
  justify-content: center;
  align-items: center;
}

.user-info-modal-sec.emails {
  margin: -10px auto 10px;
  align-items: center;
  justify-content: start;
}

.user-info-autofill-email {
  color: #fff;
  margin: 5px 10px 5px 0px;
  padding: 4px 6px;
  border: 2px solid #000000;
  border-radius: 5px;
  box-shadow: 0px 0px 30px #000000;
  background-color: #ffffff2a;
  height: fit-content;
  width: fit-content;
}

.update-modal-item-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #333333a4, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  font-size: inherit;
  font-weight: inherit;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
}

.user-info-modal-close {
  width: 100%;
  display: flex;
  font-size: 10px;
  justify-content: space-between;
  padding: 2px 10px;
}

@media screen and (max-width: 600px) {
  .user-info-modal-content {
    width: 90%;
    height: fit-content;
    max-height: 70vh;
  }

  .user-info-modal-sec {
    font-size: 12px;
  }

  .user-info-modal-sec.emails {
    justify-content: center;
  }

  .user-info-autofill-email {
    font-size: 12px;
    margin: 0px 5px 5px;
  }
}
