.client-quotes-container {
  position: relative;
  display: flex;
  width: 99%;
  overflow: auto;
  margin: 1% auto;
  padding: 3px;
  flex-direction: column;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background-color: #cacaca;
  color: #fff;
  overflow: auto;
  max-height: 80dvh;
}

.client-quotes-info-display {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: fit-content;
  margin: 8px auto;
}

.client-quotes-none-display {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: fit-content;
  margin: 8px auto 3px;
  padding: 3px 12px;
  background: linear-gradient(to right bottom, #3c1448f1, #1a3a75f3);
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 10px #000000;
}

.client-quote-item-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #fff;
  height: fit-content;
  margin: 3px auto;
  padding: 1px 5px;
  align-items: center;
  background: linear-gradient(to right bottom, #3c1448f1, #1a3a75f3);
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 10px #000000;
}

.client-quote-item-info-box {
  display: flex;
}

.client-quote-item-actions-box {
  display: flex;
}

.client-quotes-info-display ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.client-quote-circle {
  font-weight: bold;
  display: flex;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  margin: auto 3px auto 5px;
}

.bullet-color-red {
  background-color: #f00000;
}

.bullet-color-yellow {
  background-color: #e2e908;
}

.bullet-color-green {
  background-color: #05f842;
}

.client-invoices-quotes-copy-btn {
  background: linear-gradient(to right bottom, #05f842c8, #1a3a75);
  font-weight: 600;
  color: inherit;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 0px 3px;
  margin: 2px 0px 2px 8px;
  height: fit-content;
  width: fit-content;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.invoice-copy-btn {
  margin: 2px 8px 2px 2px;
}

@media screen and (max-width: 900px) {
  .client-quotes-container {
    width: 95%;
  }
}

@media screen and (max-width: 600px) {
  .client-quote-item-box {
    display: flex;
    width: 100%;
    height: fit-content;
    margin: 3px auto;
    padding: 1px 5px;
    align-items: center;
    font-size: 16px;
  }
}
