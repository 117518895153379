.update-note-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1180;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: space-evenly;
  background-color: #0000007d;
  backdrop-filter: blur(5px);
  animation: update-note-modal-fader 0.2s linear forwards;
}

@keyframes update-note-modal-fader {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.update-note-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
  padding: 2%;
  margin: auto 10px;
  z-index: 5;
  max-height: 95vh;
  height: fit-content;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 30px #000000;
  overflow: auto;
}

.update-note-modal-func-box {
  width: 100%;
  height: fit-content;
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
}

.update-note-modal-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #333333a4, #10085fd5);
  margin: 5px;
  padding: 2px 14px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  font-size: 18px;
  font-weight: 600;
  width: fit-content;
  height: fit-content;
}

.update-note-modal-close {
  z-index: 190;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 10px;
  margin: 2px 5px;
  position: sticky;
  top: 0;
  right: 3%;
  align-self: flex-end;
  background: #00000056;
  border: 1px #fff solid;
  border-radius: 50%;
  backdrop-filter: blur(2px);
}

.update-note-modal-close-x::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.update-note-modal-close-x {
  line-height: 30px;
  color: #05f842;
}

.update-note-modal-shortcut {
  width: 100%;
  font-size: 12px;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .update-note-modal-shortcut {
    width: 100%;
    font-size: 11px;
    text-align: center;
  }
}