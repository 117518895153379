.refin-req-inv-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  height: fit-content;
  width: 85%;
  border: 2px solid #fff;
  border-radius: 5px;
  background-color: #ffffff37;
  backdrop-filter: blur(3px);
  box-shadow: 0px 0px 8px #000000;
}

.refin-update-page-header {
    text-align: center;
    margin: 20px;
    padding: 15px;
    background: linear-gradient(to right bottom,#34113fcc, #2a8ebde2);
    color: #fff;
    text-shadow: -6px 6px 12px #000;
    box-shadow: -6px 6px 12px #000;
    border-radius: 6px;
    border: 2px solid #fff;
}

.refin-absolute {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.refin-inv-update-btn {
    font-size: 16px;
    position: sticky;
    z-index: 2;
    text-align: center;
    height: fit-content;
    margin: 10px;
    padding: 10px;
    background: linear-gradient(to right bottom,#34113fcc, #2a8ebde2);
    color: #fff;
    text-shadow: -6px 6px 12px #000;
    box-shadow: -6px 6px 12px #000;
    border-radius: 6px;
    border: 2px solid #fff;
}

.refin-inv-update-btn.updated{
    background: linear-gradient(to right bottom,#0000b5cc, #24e402e2);
    box-shadow: 0px 0px 12px #05F842;
}

.refin-inv-item-req {
    color: #fff;
  width: 90%;
  display: flex;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin: 8px 0px;
  border: 2px solid #fff;
  border-radius: 5px;
  background: linear-gradient(to right bottom,#34113fcc, #2a8ebde2);
  box-shadow: 0px 0px 8px #000000;
}

.refin-inv-item-req.updated {
  background: linear-gradient(to right bottom,#0000b5dc, #24e402);
  box-shadow: 0px 0px 8px #05F842;
}

.refin-inv-item-label {
    display: flex;
    width: fit-content;
    height: fit-content;
    flex-direction: column;
}

@media screen and (max-width: 900px) {
    .refin-inv-item-label {
        width: 40%;
    }    
}
