.quote-tab-container {
  z-index: 22;
  position: fixed;
  top: 35vh;
  left: 0%;
  width: fit-content;
  height: fit-content;
  border-radius: 10px 10px 0px 0px;
  padding: 0;
  margin: 0;
  transform: rotate(90deg) skew(0deg) translate3d(0, 0, 0);
  transform-origin: 0% 100%;
  -webkit-transform:rotate(90deg) skew(0deg) translate3d(0, 0, 0);
}

.quote-page-button {
  font-family: "Satisfy", cursive;
  font-size: 26px;
  font-weight: 400;
  text-decoration: none;
  background: linear-gradient(to right bottom, #182a6bf1, #6b4c76c9);
  color: #fff;
  border: none;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  padding: 4px 15px;
  margin: 0;
  cursor: pointer;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 0px 15px #000;
  text-shadow: 0px 4px 5px #000;
  transition: font-size .5s linear, opacity .5s linear;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  overflow: hidden;
}

.slide-in {
  animation: slide-in 4.5s linear forwards;
}

.quote-page-button:hover {
  opacity: 1;
  font-size: 26px;
  transition: font-size .5s linear, opacity .5s linear;
  color:#05f842;
}

@keyframes slide-in {
  0% {
    transform: translate(0px, 100px);
    opacity: 1;
  }
  22% {
    transform: translate(0px, 100px);
  }
  33% {
    transform: translate(0px);
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: .85;
  }
}

.slide-out {
  animation: slide-out .5s linear forwards;
}

@keyframes slide-out {
  0% {
    transform: translate(0px);
  }
  100% {
    transform: translate(0px, 100px);
  }
}

@media screen and (max-width: 900px) {
  @media all and (orientation:landscape) {
    .quote-tab-container {
      top: 15vh;
    }
  }
}

@media screen and (max-width: 600px) {
  .quote-tab-container {
    top: 55vh;
  }

  .quote-page-button {
    font-size: 18px;
  }

  .quote-page-button:hover {
    opacity: 1;
    font-size: 18px;
    transition: font-size .5s linear, opacity .5s linear;
  }
}
