.text-msgs-page {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.text-msgs-container {
  display: flex;
  width: 95%;
  height: 95%;
  margin: 10px auto;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 15px #000000;
  background: linear-gradient(to right bottom, #3c1448ec, #1a3a75eb);
}

.text-msgs-numbers-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 35%;
  height: 98%;
  padding: 4px;
  margin: auto;
  color: #fff;
  overflow: auto;
  background: linear-gradient(to right bottom, #3c1448ec, #1a3a75eb);
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
}

.text-msgs-numbers-item {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 2px 0;
  color: #fff;
  cursor: pointer;
}

.text-msgs-numbers-item:nth-child(odd) {
  background-color: #5251519c;
  padding: 2px 8px;
  border: 1px solid #fff;
}

.text-msgs-numbers-item:nth-child(even) {
  background-color: #3737379c;
  padding: 2px 8px;
  border: 1px solid #fff;
}

.text-msgs-numbers-item.text-item-selected {
  background: linear-gradient(to right bottom, #1a3a75c5, #05f842b7);
}

.text-msgs-item-title {
  display: flex;
  width: 100%;
  margin: 1px 0;
  font-size: 16px;
  font-weight: 400;
  justify-content: flex-start;
}

.text-title-strong {
  font-weight: 700;
  color: #05f842;
}

.text-msgs-item-name-opt-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.text-msgs-item-has-name-box {
  display: flex;
  width: fit-content;
  flex-direction: column;
}

.text-msgs-item-num {
  display: flex;
  width: 100%;
  margin: 1px 0;
  justify-content: flex-start;
  font-size: 12px;
}

.text-msgs-selected-box {
  display: flex;
  width: 63%;
  height: 98%;
  margin: auto;
}

.text-msgs-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
  font-size: 12px;
}

.text-msgs-img-btn {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
  color: #fff;
  background: none;
  border: none;
  cursor: inherit;
}

.text-view-client-btn {
  z-index: 2;
}

.text-msgs-refresh-box {
  display: flex;
  width: 95%;
  margin: 3px 0;
  padding: 3px 6px;
  background: #fd020293;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
}

@media screen and (max-width: 600px) {
  .text-msgs-container {
    flex-direction: column;
    align-items: center;
  }

  .text-msgs-numbers-box {
    width: 96%;
    height: 40%;
    padding: 10px 5px;
    margin: 10px 0 5px;
  }

  .text-msgs-selected-box {
    width: 100%;
    height: 60%;
    padding: 0;
    margin: 5px 0;
  }
}
