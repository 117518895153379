div .admin-radio-form{
  margin: 10px 0px;
  padding: 15px;
  width: 90%;
  height: 100%;
  border: 1px solid #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #000000;
  background: linear-gradient(to right bottom,#3c144884, #1a5875cd);
}

.admin-radio-center-box {
    width: 100%;
    justify-content: center;
}

.admin-radio-form-labels {
    text-align: center;
    text-shadow: -2px 2px 2px #000;
    margin: 5px;
  }