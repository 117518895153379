.login-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  min-height: fit-content;
  flex-direction: column;
}

.login-card {
  background: linear-gradient(to right bottom, #34113fb6, #21739999);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid;
  box-shadow: 0px 0px 15px #23f107;
  border-radius: 4px;
  min-width: 250px;
  margin: 30px;
  height: fit-content;
}

.login-card.registered-login {
  padding: 30px;
}

.center-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.form-input {
  margin: 10px;
  font-size: 18px;
}

.error-login {
  background-color: #610000c1;
  color: #fff;
  width: fit-content;
  text-align: center;
  padding: 5px 15px;
  margin: 4px;
  border: 2px dashed #000;
  border-radius: 5px;
  box-shadow: 0px 0px 25px #f10707;
}

.login-reset-pass {
  background-color: #00610582;
  color: #fff;
  width: fit-content;
  text-align: center;
  padding: 5px 15px;
  margin: 4px;
  border: 2px dashed #000;
  border-radius: 5px;
  box-shadow: 0px 0px 15px #000000;
}

.form-btn {
  width: fit-content;
  padding: 2px 5px;
  margin: 5px;
  background: linear-gradient(to right bottom, #34113f, #2a8fbd);
  color: #fff;
  text-wrap: nowrap;
}

.login-btn-box {
  display: flex;
  width: 100%;
  margin: 1px auto;
  justify-content: space-around;
}
