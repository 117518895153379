.finest-uploaded-container {
  display: flex;
  width: 90%;
  max-height: 90vh;
  overflow: auto;
  margin: 1%;
  padding: 1%;
  min-height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background-color: #cacaca;
}

.finest-id-input {
  width: 100%;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  border: 2px solid #fff;
  border-radius: 5px;
  background: linear-gradient(to left top, #18516b, #8f8792);
  box-shadow: 0px 0px 8px #000000;
}

.finest-ids-box {
  width: 100%;
  min-height: fit-content;
  margin: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 2px solid #fff;
  font-size: 12px;
  border: 2px solid #fff;
  border-radius: 5px;
  background: linear-gradient(to left top, #18516b, #8f8792);
  box-shadow: 0px 0px 8px #000000;
}

.finest-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 1%;
    padding: 1%;
}

.finest-image-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 1%;
    padding: 1%;
}

.finest-image-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
    height: 120px;
    margin: 1%;
    padding: 1%;
}

.finest-small-id-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    width: fit-content;
    color: #fff;
    font-size: 10px;
  }

.delete-finest-button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    font-size: 10px;
}

.finest-dark-button{
    width: fit-content;
    margin: 10px;
    padding: 5px;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 5px;
    background: linear-gradient(to left top, #18516b, #8f8792);
    box-shadow: 0px 0px 8px #000000;
  }
  

@media screen and (max-width: 900px) {
  .finest-uploaded-container {
    width: 90%;
  }

  .finest-small-id-text {
    margin: 5px 2px;
    width: fit-content;    
    font-size: 8px;
  }

  .finest-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 2px;
    padding: 2px;
}

  .finest-image-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px;
    height: 80px;
    margin: 1%;
    padding: 1%;
}
}
