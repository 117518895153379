.finest-container {
  display: flex;
  justify-content: center;
  width: 95%;
  padding: 3% 2%;
  box-sizing: border-box;
  height: 80vh;
  border: 4px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #000000;
  background: linear-gradient(to right bottom,#3c144834, #1a3a7579);
  backdrop-filter: blur(2px);
}

.expanding-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  transition: 0.5s;
  margin: 0 1%;
  background: linear-gradient(to right bottom,#3c144834, #1a3a7579);
  backdrop-filter: blur(2px);
  box-shadow: -5px 5px 10px rgb(0, 0, 0);
  border-radius: 4px;
  line-height: 0;
  height: 100%;
}

.expanding-box img {
  max-height: 90%;
  margin: 1%;
  border-radius: 4px;
  object-fit: cover;
  transition: 0.5s;
  filter: grayscale(0.9) brightness(40%);
  opacity: 0.1;
}

.expanding-box span {
  font-size: 3.8vh;
  display: block;
  text-align: center;
  height: 10vh;
  line-height: 2.6;
}

.expanding-box.selected {
  flex: 1 1 80%;
}
.expanding-box.selected img {
  opacity: 1;
  height: auto;
  width: auto;
  max-width: 44%;
  max-height: 90%;
  filter: grayscale(0) brightness(100%);
}

@media screen and (max-width: 1100px) {
  .finest-container {
    height: 55vh;
  }
}
@media screen and (max-width: 900px) {
  .finest-container {
    height: 45vh;
  }
}

@media screen and (max-width: 600px) {
  .finest-container {
    display: flex;
    justify-content: center;
    width: 99%;
    padding: 3% 1%;
    box-sizing: border-box;
    height: 45vh;
    border: none;
    border-radius: none;
    box-shadow: none;
    background-color: none;
  }

  .expanding-box {
    display: hidden;
    width: 0%;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    margin: 0px;
    border: 1px solid #fff;
  }

  .expanding-box img {
    display: hidden;
    width: 0%;
    margin: 0px;
    border: none;
  }
  .expanding-box.selected {
    display: flex;
    flex: 1 1 95%;
  }
  .expanding-box.selected img {
    height: auto;
    width: auto;
    max-width: 45%;
    max-height: 90%;
    margin: 2% 1%;
  }
}
@media screen and (max-width: 450px) {
  .finest-container {
    height: 35vh;
  }
}
