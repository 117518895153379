.contact-page-container {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.contact-links {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0 15px;
}

.contact-icons {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
  margin: 20px 0px 0px;
}

.email-icons {
  margin: 0px 10px;
}

.contact-info-box {
  display: flex;
  width: 90%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 15px;
  padding: 20px;
  border: 4px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background: linear-gradient(to right bottom,#461854d7, #2a8ebdda);
}

.contact-ul {
  text-align: center;
  color: #fff;
  font-weight: 600;
  text-shadow: -3px 3px 5px #000;
  height: fit-content;
  max-width: 100%;
  width: fit-content;
  list-style: none;
  margin: 15px;
  padding: 0px;
  line-height: 1.6rem;
}

.contact-ul li {
  margin: 25px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contact-ul li a {
  font-weight: 400;
  text-decoration: none;
  color: #05f842;
}

.contact-ul li a:hover {
  color: #00b72e;
}

.contact-thanks {
  text-align: center;
  color: #fff;
  font-weight: 700;
  text-shadow: -3px 3px 5px #000;
  height: fit-content;
  width: fit-content;
  list-style: none;
  margin: 15px;
  padding: 20px;
  line-height: 1.6rem;
}

@media screen and (max-width: 600px) {
  .contact-ul li a {
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    color: #05f842;
  }

  .contact-info-box {
    padding: 10px;
  }
}
