.footer-box {
  width: 100%;
  margin: 25px 0 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-bg-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  background-color: #3c3c3c84;
  backdrop-filter: blur(3px);
}

.footer-hdr {
  color: #fff;
  margin-bottom: 10px;
  text-shadow: -3px 3px 8px #000;
}

.footer-img {
  height: 150px;
  width: 150px;
  margin: 10px 5px;
}

.footer-social {
  margin: 10px 0;
  width: 70%;
}

.footer-privacy-tos-box {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0 3px;
}

.footer-btn {
  border: none;
  background: none;
  border-bottom: 1px solid #fff;
  color: #fff;
  font-size: 18px;
  margin: 2px 8px;
}

@media screen and (max-width: 680px) {
  .footer-img {
    height: 60px;
    width: 60px;
  }

  .footer-social {
    width: 95%;
  }

  .footer-btn {
    font-size: 12px;
  }
}
