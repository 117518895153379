.client-invoice-quote-modal-page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
  }
  
  .client-invoice-quote-modal-page {
    width: 95%;
    max-width: 900px;
    min-height: 900px;
    position: relative;
    margin: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    border: 2px solid #000000;
    border-radius: 5px;
    box-shadow: -5px 3px 10px #000000;
    background: #fffffff8;
  }
  
  .client-invoice-quote-modal-page-print-btn-box {
    width: 100%;
    z-index: 22;
    position: fixed;
    top: 10px;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    height: fit-content;
  }
  
  .client-invoice-quote-modal-paid-invoice-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .client-invoice-quote-modal-paid-invoice-box-none {
    display: none;
  }
  
  .client-invoice-quote-modal-paid-invoice {
    position: absolute;
    top: 150px;
    left: 65%;
    transform: translate(-50%, -50%) rotate(-25deg);
    font-size: 4em;
    padding: 5px 20px;
    border-radius: 10px;
    border: 10px solid #de0303;
    color: #de0303;
    opacity: .0;
    animation: slammer .5s linear forwards;
    animation-delay: 1s;
    overflow: hidden;
  }
  
  @keyframes slammer {
    0% {
      transform: scale(8) translate(-50%, -50%) rotate(25deg);
      opacity: 0;
    }
  
    100% {
      transform: scale(1) translate(-50%, -50%) rotate(-25deg);
      opacity: .5;
    }
  }
  
  .client-invoice-quote-modal-paid-invoice-static {
    position: absolute;
    top: 150px;
    left: 65%;
    transform: translate(-50%, -50%) rotate(-25deg);
    font-size: 4em;
    padding: 5px 20px;
    border-radius: 10px;
    border: 10px solid #de0303;
    color: #de0303;
    opacity: .5;
    overflow: hidden;
  }
  
  .client-invoice-quote-modal-top-info {
    width: 100%;
    min-height: 10px;
    display: flex;
    margin: 0px 0px 4px;
    padding: 0;
    justify-content: space-around;
  }
  
  .client-invoice-quote-modal-logo-img {
    height: 60px;
    width: 60px;
    padding: 2px;
    background-color: #f7f7f7;
    box-shadow: 0px 0px 4px #000000;
    border: 1px solid #000000;
    border-radius: 8px 8px;
    margin-bottom: 3px;
  }
  
  .client-invoice-quote-modal-img-box {
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: center;
  }
  
  .client-invoice-quote-modal-info-accept-box {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin: 2px 0 10px;
    height: fit-content;
    z-index: 2;
  }
  
  .client-invoice-quote-modal-company-info {
    width: 44%;
  }
  
  .client-invoice-quote-modal-company-info-inner {
    width: fit-content;
  }
  
  .client-invoice-quote-modal-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .client-invoice-quote-modal-accept-box {
    display: flex;
    width: 44%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
  
  .client-invoice-quote-modal-text-right {
    text-align: end;
  }
  
  .client-invoice-quote-modal-accept-button {
    height: fit-content;
    width: fit-content;
    margin: 4px 0px;
    padding: 2px 4px;
    font-size: 16px;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 5px;
    background: linear-gradient(to left top, #18516b, #8f8792);
    box-shadow: 0px 0px 8px #000000;
  }
  
  .client-invoice-quote-modal-to-box {
    display: flex;
    width: 90%;
    height: fit-content;
    margin: 8px auto;
    z-index: 2;
  }
  
  .client-invoice-quote-modal-to-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 1px 5px;
  }
  
  .client-invoice-quote-modal-to-section:first-of-type {
    flex-grow: 2;
  }
  
  .client-invoice-quote-modal-items-container {
    display: flex;
    width: 100%;
    height: fit-content;
    margin: 8px auto;
    flex-direction: column;
    z-index: 2;
  }
  
  .client-invoice-quote-modal-items-title {
    display: flex;
    width: 100%;
  }
  
  .client-invoice-quote-modal-items-item {
    display: flex;
    width: 100%;
    height: fit-content;
    margin: 2px 0;
    padding: 0 2px;
    font-size: 18px;
  }
  
  .client-invoice-quote-modal-items-item:nth-of-type(even) {
    display: flex;
    width: 100%;
    height: fit-content;
    margin: 2px 0;
    background-color: #e2e2e2;
  }
  
  .client-invoice-quote-modal-items-item :first-child {
    display: flex;
    width: 32%;
    flex-wrap: wrap;
    height: fit-content;
  }
  
  .client-invoice-quote-modal-items-item :nth-child(2) {
    display: flex;
    width: 17%;
    flex-wrap: wrap;
    height: fit-content;
  }
  
  .client-invoice-quote-modal-items-item :nth-child(3) {
    display: flex;
    width: 17%;
    flex-wrap: wrap;
    height: fit-content;
  }
  
  .client-invoice-quote-modal-items-item :nth-child(4) {
    display: flex;
    width: 17%;
    flex-wrap: wrap;
    height: fit-content;
    justify-content: center;
    text-align: center;
  }
  
  .client-invoice-quote-modal-items-item :last-child {
    display: flex;
    width: 17%;
    justify-content: flex-end;
    margin-left: auto;
  }
  
  .client-invoice-quote-modal-totals-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    margin: 10px 0;
    padding: 0 10px;
  }
  
  .client-invoice-quote-modal-totals-box div:last-of-type {
    border-top: 1px solid #000;
    font-size: 25px;
  }
  
  .client-invoice-quote-modal-hr {
    height: 1px;
    background-color: #000000;
    width: 100%;
    margin: 2px auto;
  }
  
  .client-invoice-quote-modal-none-div {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    align-items: center;
  }
  
  .client-invoice-quote-modal-none-header {
    display: flex;
    width: 90%;
    height: fit-content;
    flex-direction: column;
    align-items: center;
    margin: 25px auto 0;
    padding: 10px;
    background: linear-gradient(to right bottom, #461854d7, #2a8ebdda);
    border: 4px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #000000;
    font-size: 20px;
    color: #fff;
    text-shadow: -3px 3px 5px #000;
  }
  
  .client-invoice-quote-modal-contact-info-box {
    display: flex;
    width: 100%;
    max-width: 700px;
  }
  
  .client-invoice-quote-modal-invoice-warning-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 3px;
    border: 4px solid #de0303;
    box-shadow: 0px 0px 5px #000;
    padding: 4px;
  }
  
  .client-invoice-quote-modal-accepted-box {
    display: flex;
    width: 100%;
    margin: 3px;
    border: 4px solid #05f842;
    box-shadow: 0px 0px 5px #000;
    padding: 4px;
  }
  
  .client-invoice-quote-modal-invoice-warning {
    color: #de0303;
    text-shadow: -2px 2px 4px #bababa;
  }
  
  .client-invoice-quote-modal-page-note {
    z-index: 2;
  }
  
  .client-invoice-quote-modal-page-paid-by span {
    font-size: 16px;
  }
  
  @media screen and (max-width: 600px) {
    .client-invoice-quote-modal-page {
      width: 97%;
      max-width: 900px;
      min-height: 600px;
      margin: 40px 5px 5px;
      padding: 5px;
    }
  
    .client-invoice-quote-modal-paid-invoice {
      font-size: 4em;
    }
  
    .client-invoice-quote-modal-items-item {
      font-size: 12px;
    }
    .client-invoice-quote-modal-items-item :first-child {
      display: flex;
      flex-direction: row;
      width: 33%;
      flex-wrap: wrap;
      height: fit-content;
      text-wrap: wrap;
    }
  
    .client-invoice-quote-modal-items-item :nth-child(2) {
      display: flex;
      width: 17%;
      flex-wrap: wrap;
      height: fit-content;
    }
  
    .client-invoice-quote-modal-items-item :nth-child(3) {
      display: flex;
      width: 15%;
      flex-wrap: wrap;
      height: fit-content;
      flex-shrink: 1;
    }
  
    .client-invoice-quote-modal-items-item :nth-child(4) {
      display: flex;
      width: 15%;
      flex-wrap: wrap;
      height: fit-content;
      flex-shrink: 1;
    }
  
    .client-invoice-quote-modal-items-item :last-child {
      display: flex;
      width: 18%;
      justify-content: flex-end;
    }
  
    .client-invoice-quote-modal-items-container {
      width: 100%;
    }
  
    .client-invoice-quote-modal-to-box {
      width: 100%;
    }
  
    .client-invoice-quote-modal-to-bubble {
      font-size: 13px;
    }
  
    .client-invoice-quote-modal-totals-box {
      font-size: 16px;
    }
  
    .client-invoice-quote-modal-page-paid-by span {
      font-size: 14px;
    }
  
    .client-invoice-quote-modal-page-print-btn-box {
      display: none;
    }
  }
  
  @media print {
    .client-invoice-quote-modal-page {
      width: 95%;
      max-width: 900px;
      position: relative;
      margin: 15px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      border: none !important;
      border-radius: none !important;
      box-shadow: none !important;
    }
  
    .client-invoice-quote-modal-page-print-btn-box {
      display: none;
    }
  }