.invite-user-container {
  display: flex;
  max-height: 80vh;
  overflow: auto;
  width: 90%;
  margin: 1%;
  padding: 1%;
  min-height: fit-content;
  flex-direction: column;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background-color: #cacaca;
}

.invite-user-email-input {
  width: 100%;
  height: fit-content;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid #fff;
  font-size: 12px;
  border: 2px solid #fff;
  border-radius: 5px;
  background: linear-gradient(to left top, #18516b, #8f8792);
  box-shadow: 0px 0px 8px #000000;
}

.invite-user-sec {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: fit-content;
  width: 95%;
  margin: 10px auto;
  padding: 2px 8px;
}

.invite-user-sec.emails {
  margin: -10px auto 10px;
  align-items: center;
  justify-content: start;
}

.invite-user-autofill-email {
  color: #fff;
  margin: 10px 10px 5px 0px;
  padding: 4px 6px;
  border: 2px solid #000000;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #000000;
  background-color: #ffffff2a;
}

.invite-user-autofill-email.selected {
  color: #05f842;
  margin: 10px 10px 5px 0px;
  padding: 4px 6px;
  border: 2px solid #05f842;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #000000;
  background-color: #4b4b4bae;
}

#invite-user-button {
  width: fit-content;
  margin: 20px;
  padding: 8px;
  color: #fff;
  border: 4px solid #fff;
  border-radius: 5px;
  background: linear-gradient(to left top, #18516b, #8f8792);
  box-shadow: 0px 0px 20px #000000;
  transition: all 0.5s;
}
#invite-user-button:hover,
#invite-user-button:focus {
  padding: 8px 12px;
  color: #3a3a3a;
  background: linear-gradient(to right bottom, #a3b8a5, #ffffff);
}

.invite-exists {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  color: #fff;
  border: 1px solid #fff;
  padding: 3px 5px;
  margin: 0 20px;
  border-radius: 5px;
  background: linear-gradient(to left top, #a40000, #795d02);
  box-shadow: 0px 0px 10px #000000;
}

.invite-exists.success {
  background: linear-gradient(to left top, #0c4200, #795d02);
  box-shadow: 0px 0px 10px #000000;
}

@media screen and (max-width: 600px) {
  .invite-user-sec.emails {
    justify-content: center;
  }

  .invite-user-autofill-email {
    font-size: 12px;
    margin: 10px 2px 5px;
    padding: 4px;
  }
}
