.list-user-container {
    display: flex;
    max-height: 80vh;
    overflow: auto;
    width: 90%;
    margin: 1%;
    padding: 1%;
    min-height: fit-content;
    flex-direction: column;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #000000;
    background-color: #cacaca;
  }
  
  .list-user-content {
    width: 100%;
    margin: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 12px;
    border: 2px solid #fff;
    border-radius: 5px;
    background: linear-gradient(to left top, #18516b, #8f8792);
    box-shadow: 0px 0px 8px #000000;
  }

  .list-users-actions-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .user-deactivate-text-confirm {
    width: fit-content;
  }

  .user-deactivate-confirm {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .user-deactivate-btn {
    width: fit-content;
    padding: 2px;
    margin: 2px 5px;
    border: none;
    background: none;
    color: #fff;
  }

  .user-list-box {
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    margin: 10px auto;
    width: 95%;
    border: 2px solid #fff;
    border-radius: 5px;
    background: linear-gradient(to left top, #58186b57, #121b6a76);
    box-shadow: 0px 0px 8px #000000;
  }

  .user-name-box {
    margin: 2px 8px;
    min-width: 55%;
  }

  .user-email-word-break {
    overflow-wrap: break-word;
  }
  
  .user-name-box h5 {
    color: #05F842;
  }

  .selected-listed-user {
    background: linear-gradient(to left top, #121b6a76, #05f8429d);
  }

  .list-users-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 600;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #0000007d;
    backdrop-filter: blur(3px);
    animation: list-users-modal-fader 0.2s linear forwards;
  }
  
  @keyframes list-users-modal-fader {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }

  .list-users-modal-content {
    color: #fff;
    background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
    width: 80%;
    padding: 15px;
    z-index: 15;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border: 2px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 30px #000000;
    max-height: 90vh;
    overflow: auto;
  }

  .list-users-modal-hdr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2px 10px;
    text-shadow: -2px 2px 6px #000000;
  }

  .list-users-close-modal-btn {
    color: #fff;
    padding: 4px 6px;
    border: 1px solid #000000;
    border-radius: 5px;
    box-shadow: 0px 0px 14px #000000;
    background-color: #ffffff2a;
    font-size: 10px;
    height: fit-content;
    width: fit-content;
  }

  .list-users-wage-input-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  @media screen and (max-width: 600px) {
    
}
  