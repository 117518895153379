.navbar {
  background: linear-gradient(to right bottom,#34113fb6, #21739999);
  border-bottom: 2px solid #fff;
  backdrop-filter: blur(5px);
}

.nav-hdr {
  font-family: "Noto", serif;
  text-shadow: -3px 3px 5px #595959;
  color: #05f842;
  margin: 20px;
}

.nav-motto{
  margin: 0 20px;
}

.nav-links-hov{
  color:#ffffff;
  margin: 4px 10px;
  display: block;
  font-size: 20px;
  text-decoration: none;
}

.nav-links-hov.small {
  color: #000;
  margin: 0;
  width: 100%;
  display: block;
  font-size: 20px;
  text-decoration: none;
}

.nav-links-hov:hover{
  color:#05f842;
}

@media screen and (max-width:600px){
  .nav-hdr {
    margin: 0;
    font-size: 1.4rem;
  }

  .nav-motto{
    margin: 0;
  }
  
}
