.client-invoices-container {
    position: relative;
    display: flex;
    width: 99%;
    margin: 1% auto;
    padding: 3px;
    flex-direction: column;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #000000;
    background-color: #cacaca;
    color: #fff;
    overflow: auto;
    max-height: 80dvh;
  }
  
  .client-invoices-info-display {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: fit-content;
    margin: 8px auto;
  }
  
  .client-invoices-none-display {
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: fit-content;
    margin: 8px auto 3px;
    padding: 3px 12px;
    background: linear-gradient(to right bottom, #3c1448f1, #1a3a75f3);
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: -2px 2px 10px #000000;
  }
  
  .client-invoices-item-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    color: #fff;
    height: fit-content;
    margin: 3px auto;
    padding: 1px 5px;
    align-items: center;
    background: linear-gradient(to right bottom, #3c1448f1, #1a3a75f3);
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: -2px 2px 10px #000000;
  }

  .invoice-item-info-box {
    display: flex;
  }

  .invoice-item-actions-box {
    display: flex;
  }
  
  .client-invoices-info-display ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%;
  }
  
  .client-invoices-circle {
    font-weight: bold;
    display: flex;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    margin: auto 3px auto 5px;
    background-color: #fff;
  }

  .client-invoices-paid-icon-box {
    display: flex;
    height: 100%;
    width: fit-content;
    align-items: center;
    justify-content: center;
    margin: auto 7px auto 0;
  }

  .client-invoices-paid-icon {
    font-weight: 600;
    font-size: 8px;
    border: 1px solid #05f842;
    color: #05f842;
    background-color: none;
    padding: 1px 3px;
    transform: rotate(-25deg);
  }
  
  .client-invoices-pending-icon {
    font-weight: 600;
    font-size: 8px;
    border: 1px solid #e2e908;
    color: #e2e908;
    background-color: none;
    padding: 1px 3px;
  }
  
  @media screen and (max-width: 900px) {
    .client-invoices-container {
      width: 95%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .client-invoices-item-box {
      display: flex;
      width: 100%;
      height: fit-content;
      margin: 3px auto;
      padding: 1px 5px;
      align-items: center;
      font-size: 16px;
    }
  }
  