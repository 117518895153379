.email-page-container {
  display: flex;
  width: 95%;
  margin: 1% auto;
  padding: 1%;
  flex-direction: column;
  align-items: center;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 30px #000000;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
}

.email-page-filter {
  backdrop-filter: blur(3px);
}

.email-page {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.email-info-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border: none;
  background: none;
  font: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.email-item-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.email-open-btns {
  display: flex;
  flex-direction: column;
  min-width: 20%;
  max-width: 100%;
  flex-grow: 1;
  width: -webkit-fill-available;
}

.email-item {
  position: relative;
  background-color: #525151;
  color: #fff;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  width: 30%;
  max-width: 98%;
  margin: 2px auto;
  padding: 3px 7px;
  border: 1px solid #bdbbbb;
  border-radius: 5px;
  box-shadow: -3px 3px 6px #000000;
}

.email-item.read-email {
  background-color: #313131b5;
}

.email-item.read-email.email-item-important {
  background-color: #946d0180;
}

.email-item.read-email.email-item-urgent {
  background-color: #9421018e;
}

.email-item.email-item-important {
  background-color: #946d01;
}

.email-item.email-item-urgent {
  background-color: #942101;
}

/* .email-item::before {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate();
  content: "";
  background: transparent;
  width: 100%;
  height: 100%;
} */

.email-item-holders {
  color: #05f842;
}

.email-item-date {
  color: #05f842;
}

.overflow-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.email-has-attach.att-error {
  background-color: #c80404;
}

.email-actions-box {
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1px;
}

.email-page-action-img-button {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
  color: #fff;
  background: none;
  border: none;
  cursor: inherit;
}

.email-page-page-control-limit {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
  color: #fff;
  background: none;
  border: none;
  cursor: inherit;
  margin: 0 1px;
  padding: 1px 2px;
}

.email-page-page-control-limit.green-text {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
  color: #05f842;
  background: none;
  border: none;
  cursor: inherit;
  margin: 0 1px;
  padding: 1px 2px;
}

.email-page-page-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  margin: 9px auto 2px;
  width: 100%;
}

.email-page-page-control-button {
  position: absolute;
  top: 0;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  width: 22px;
  height: 20px;
  color: #fff;
  background: none;
  border: none;
  cursor: inherit;
  padding: 0;
  margin: 0;
}

.email-page-page-control-button-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  color: #fff;
  background-color: #188103;
  box-shadow: -2px 2px 4px #000;
  border-radius: 12px;
  border: none;
  cursor: inherit;
  padding: 0px;
  margin: 0 4px;
}

.email-actions-button-box {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: fit-content;
  margin: 2px 0;
}

.email-page-search-box {
  width: 95%;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  display: flex;  
  flex-wrap: nowrap;
}

.email-page-search-input {
  width: 100%;
  height: fit-content;
  display: flex;  
  flex-shrink: 1;
}

.email-page-send-box {
  font-size: 20px;
  font-weight: 600;
}

.email-archive-button {
  color: #fff;
  z-index: 22;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
}

.email-archive-button-show {
  color: #fff;
  z-index: 22;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
}

.email-has-attach {
  display: block;
  white-space: nowrap;
  color: #ffffff;
  background-color: #188103;
  box-shadow: -2px 2px 4px #000;
  padding: 2px 8px;
  border-radius: 5px;
  margin: 5px;
  width: fit-content;
}

.email-archive-snack {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.email-archive-item-snack {
  width: fit-content;
  margin-right: 5px;
}

.email-archive-snack-btn {
  display: flex;
  width: fit-content;
  margin: 2px 4px;
  padding: 2px 5px;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
  background: #ffffff;
  color: #000000;
  border: 2px solid #000000;
  border-radius: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.email-item-loading {
  overflow: hidden;
  position: relative;
  background-color: #525151;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 90%;
  margin: 2px auto;
  padding: 3px 7px;
  border: 1px solid #bdbbbb;
  border-radius: 5px;
  box-shadow: -3px 3px 6px #000000;
}

.email-page-archive-selection {
  display: flex;
  justify-content: space-between;
  width: 90%;
  color: #fff;
  margin: 10px 0;
}

@keyframes blur-scroll {
  0% {
    left: -30%;
  }
  100% {
    left: 110%;
  }
}

.email-item-loading::after {
  position: absolute;
  left: -30%;
  content: "";
  overflow: hidden;
  z-index: 5;
  box-shadow: -0px 0px 70px #a9a9a9e4;
  background-color: #efeded1f;
  width: 20%;
  height: 100%;
  transform: skew(-30deg);
  animation: blur-scroll 4s linear forwards infinite;
}

.email-item-wrapper:nth-child(1) .email-item-loading::after {
  animation-delay: 0s;
}
.email-item-wrapper:nth-child(2) .email-item-loading::after {
  animation-delay: 0.1s;
}
.email-item-wrapper:nth-child(3) .email-item-loading::after {
  animation-delay: 0.2s;
}
.email-item-wrapper:nth-child(4) .email-item-loading::after {
  animation-delay: 0.3s;
}
.email-item-wrapper:nth-child(5) .email-item-loading::after {
  animation-delay: 0.4s;
}
.email-item-wrapper:nth-child(6) .email-item-loading::after {
  animation-delay: 0.5s;
}
.email-item-wrapper:nth-child(7) .email-item-loading::after {
  animation-delay: 0.6s;
}
.email-item-wrapper:nth-child(8) .email-item-loading::after {
  animation-delay: 0.7s;
}
.email-item-wrapper:nth-child(9) .email-item-loading::after {
  animation-delay: 0.8s;
}
.email-item-wrapper:nth-child(10) .email-item-loading::after {
  animation-delay: 0.9s;
}
.email-item-wrapper:nth-child(11) .email-item-loading::after {
  animation-delay: 1s;
}
.email-item-wrapper:nth-child(12) .email-item-loading::after {
  animation-delay: 1.1s;
}
.email-item-wrapper:nth-child(13) .email-item-loading::after {
  animation-delay: 1.2s;
}
.email-item-wrapper:nth-child(14) .email-item-loading::after {
  animation-delay: 1.3s;
}
.email-item-wrapper:nth-child(15) .email-item-loading::after {
  animation-delay: 1.4s;
}

.email-item-loading::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 3;
  background-color: #bbbbbb1f;
  backdrop-filter: blur(4px);
  width: 110%;
  height: 100%;
}

.email-item-holders-loading {
  color: #b6b6b6;
}

.email-item-date-loading {
  color: #b6b6b6;
}

.email-item-small-date {
  min-width: fit-content;
  margin: 0 4px;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
}
.email-item-small-date:nth-child(1) {
  margin-right: 4px;
}

.email-has-attach.loading {
  color: #b6b6b6;
  background-color: #5b5b5b;
}

@media screen and (max-width: 900px) {
  .email-info-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .email-item-small-date {
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .email-item {
    width: 60%;
    flex-direction: column;
  }

  .email-item-small-date {
    justify-content: flex-start;
    width: 100%;
    margin: 0;
  }

  .email-actions-box {
    width: 100%;
    justify-content: flex-start;
    margin: 3px;
  }

  .email-actions-button-box {
    flex-wrap: wrap;
    font-size: 10px;
  }
}
