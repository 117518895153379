.admin-navbar {
  background: linear-gradient(to right bottom, #34113fb6, #21739999);
  border-bottom: 2px solid #fff;
  backdrop-filter: blur(5px);
}

.admin-nav-hdr-container {
  display: flex;
  height: fit-content;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.admin-nav-menu-wrapper {
  position: fixed;
  top: 90dvh;
  right: 1%;
  z-index: 1000;
  color: #fff;
}

.admin-nav-hdr {
  font-family: "Noto", serif;
  text-shadow: -3px 3px 5px #595959;
  color: #05f842;
  margin: 20px;
  width: fit-content;
}

.admin-logout-button{
  height: fit-content;
  width: fit-content;
  color: #fff;
  border: none;
  border-radius: 5px;
  background: none;
}

.admin-nav-hdr-sml-submenu-title-arrow {
  margin-left: auto;
}

@media screen and (max-width: 900px) {
  .admin-nav-hdr {
    margin: 0;
    font-size: 1.4rem;
  }

  .admin-logout-button {
    margin: auto;
    color: #000000;
  }
}

@media screen and (max-width: 600px) {
  .admin-nav-hdr {
    margin: 0;
    font-size: 1.4rem;
  }
}
