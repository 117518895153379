.our-doors-image-box {
    width: 100%;
    min-height: 50vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.more-images {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px;
}

.more-images-box {
    border: 2px solid #fff;
    border-radius: 4px;
    box-shadow: -3px 3px 8px #000;
    margin: 10px;
    background: linear-gradient(to right bottom,#3c144834, #1a3a7579);
}

.more-images-img-box {
    width: 100%;
    display: flex;
    justify-content: center;
}

.more-images-img {
    border: 2px solid #fff;
    border-radius: 4px;
    box-shadow: -3px 3px 8px #000;
    margin: 10px;
    height: 160px;
    width: 120px;
}

.more-images-stain-hdr {
    text-align: center;
    color: #fff;
    padding: 5px;
    text-shadow: -3px 3px 6px #000;
    font-size: 18px;
}

.our-doors-page-flex-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.click-to-enlarge {
    margin-top: 4px;
    font-size: 12px;
}


@media screen and (max-width: 600px) {
    .more-images {
        margin: 5px;
    }
    
    .more-images-box {
        width: 45%;
        margin: 5px;
        padding: 5px;
    }

    .more-images-img {
        margin: 5px 3px;
        height: auto;
        width: auto;
        max-width: 48%;
    }

    .more-images-stain-hdr {
        font-size: 13px;
    }
  }