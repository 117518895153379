.review-carousel {
  min-height: 30vh;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.review-invis {
    filter: blur(40px);
    opacity: .01;
    position: relative;
    width: 95%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.review-vis {
  width: 95%;
}

.reviews-absolute-box {
  content: '';
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.review-vis #review-animation-box{
  width: 100%;
  transition: 1s filter .5s linear, opacity 1s .5s linear;
  -webkit-transition: 1s -webkit-filter .5s linear, opacity 1s .5s linear;
  -moz-transition: 1s -moz-filter .5s linear, opacity 1s .5s linear;
  -ms-transition: 1s -ms-filter .5s linear, opacity 1s .5s linear;
  -o-transition: 1s -o-filter .5s linear, opacity 1s .5s linear;
  opacity: 1;
  filter: blur(0px);
  animation: sliderReviewVis 1s ease-in-out .5s forwards;
}

@keyframes sliderReviewVis {
  0% {translate: 50%}
  100% {translate: 0%}
}

.flex-start {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 20%;
}

.review-box {
  width: 80%;
  min-height: 30vh;
  height: fit-content;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 20px;
  background-color: #ffffff;
  border-radius: 5px;
  color: #000000;
  border: 4px solid #000000;
  box-shadow: 0px 0px 30px #05f842;
}

.spinner-box {
  display: flex;
  height: 170px;
  justify-content: center;
  align-items: center;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: all 1000ms;
}

.reviews-playing {
  align-self: flex-start;
  font-size: 20px;
  font-weight: 500;
  animation: flash 1s linear infinite;
}
.reviews-playing span{
  align-self: flex-start;
  font-size: 14px;
  font-weight: 400;
}

.playing-bar-box {
  display: flex;
  justify-content: center;
  width: 90%;
  position: relative;
  overflow: hidden;
}

.reviews-playing-bar {
  z-index: 1;
  margin: 15px 0px 2px;
  border-radius: 10px;
  height: 8px;
  width: 100%;
  background-color: #4c4c4c;
}

.playing-animation {
  position: absolute;
  z-index: 5;
  margin: 15px 0px 2px;
  border-radius: 10px;
  height: 8px;
  width: 0%;
  top: 0;
  background-color: #05f842;
  animation: playingBar 8s linear forwards;
}

@keyframes playingBar {
  0% {width: -0%}
  100% {width: 100%}
}

@keyframes flash {
  0% {opacity: 1;}
  50% {opacity: .4;}
  100% {opacity: 1;}
}

@media screen and (max-width: 600px) {
  .spinner-box {
    height: 200px;
  }

  .reviews-playing {
    font-size: 16px;
    font-weight: 400;
  }
}
