.view-quote-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  display: flex;
  width: 100%;
  height: 100dvh;
  align-items: center;
  justify-content: center;
  background-color: #0000007d;
  backdrop-filter: blur(3px);
  animation: view-quote-modal-fader 0.2s linear forwards;
}

@keyframes view-quote-modal-fader {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.view-quote-modal-content {
  position: relative;
  height: 95%;
  width: 95%;
  max-width: 1200px;
  overflow: auto;
  padding: 20px;
}

.view-quote-modal-close {
  z-index: 50;
  width: fit-content;
  color: #fff;
  margin: 1px 5px;
  padding: 1px 7px;
  position: sticky;
  top: 0;
  left: 90%;
  align-self: flex-start;
  background: #00000056;
  border: 1px #fff solid;
  border-radius: 20px;
  backdrop-filter: blur(2px);
}

.view-quote-modal-resized {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0px auto;
  width: 95%;
  height: fit-content;
  justify-content: center;
  align-items: flex-start;
}

.view-quote-history-ul {
  color: #fff;
  width: 45%;
}

.view-quote-modal-invoice-box {
  display: flex;
  width: 90%;
  margin: 3px auto;
  flex-direction: column;
  color: #fff;
}

.view-quote-modal-invoice-create-box {
  display: flex;
  width: 100%;
  margin: 3px auto;
  justify-content: flex-end;
}

.view-quote-modal-invoice-date {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  padding: 2px 5px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
}

.view-quote-modal-invoice-paid-box.MuiFormControl-root {
  width: 95%;
  margin: 8px auto;
  padding: 5px 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
}

.MuiFormControl-root.view-quote-modal-invoice-paid-end-box {
  width: 100%;
  margin: 8px auto;
  padding: 5px 10px;
}

.view-quote-modal-invoice-date-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.view-quote-modal-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
}

@media screen and (max-width: 1100px) {
  .view-quote-modal-invoice-date-select {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .view-quote-modal-content {
    width: 95%;
    height: fit-content;
    max-height: 94dvh;
    padding: 0;
  }

  .view-quote-modal-resized {
    width: 98%;
    flex-direction: column;
  }

  .view-quote-history-ul {
    color: #fff;
    width: 95%;
    margin: 5px auto;
  }
}
