.carousel {
    width: auto;
    height: auto;
    max-width: 100%;
}

.carousel-container {
    position: relative;
    height: fit-content;
    width: 90%;
    border: 4px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #000000;
    background-color: #7d7d7d;
    margin: 14px 0;
    overflow: hidden;
}

.carousel-img {
    width: auto;
    height: auto;
    max-width: 100%;
}

.hdr-wrapper {
    position: absolute;
    width: 100%;
    top: 3%;
}

.before-after-wrapper {
    position: absolute;
    width: 100%;
    top: 35%;
}

.home-hdr {
    z-index: 1;
    position: relative;
    top: 0vh;
    text-align: center;
    color: #fff;
    text-shadow: -3px 3px 5px #000;
    padding: 15px 10px;
    background: #0000008a;
}

.before-after {
    z-index: 1;
    position: relative;
    width: fit-content;
    padding: 6px;
    left: 5%;
    color: #fff;
    text-shadow: -3px 3px 5px #000;
    background: #0000008a;
    box-shadow: 0px 0px 30px #05F842;
    border-radius: 5px;
}

@media screen and (max-width: 900px) {
    .carousel-container { 
        width: 70%;
        margin: 2%;
    }
}

@media screen and (max-width: 600px) {
    .carousel-container { 
        display: flex;
        align-items: center;
        justify-self: center;
        width:fit-content;
        margin: 3%;
    }

    .carousel {
        height: fit-content;
    }
}