body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background-div {
  position: fixed;
  height: 100vh;
  width: 100%;
  filter: grayscale(0.9);
  overflow: hidden;
}

.bg-img {
  background-image: url(./assets/images/wood-and-star-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
}

.content::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 300vh;
  width: 100%;
  min-width: 320px;
}

.content {
  position: absolute;
  left: 0;
  top: 0;
  height: fit-content;
  width: 100%;
  min-width: 320px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 900px) {
  .bg-img {

    content: "";
    background-image: url(./assets/images/wood-and-star-bg.jpg);
    display: block;
    background-position: center;
    width: 100%;
    height: 120%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    transform: translate3d(0,0,0); 
  }
}
