.uploaded-container {
  width: 100%;
  height: fit-content;
  background-color: #868585;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: -10px 10px 30px #000000;
}

.uploaded-box {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

input[type="file" i] {
  display: none;
}

.custom-file-upload {
  width: fit-content;
  padding: 5px 8px;
  background: linear-gradient(to right top, #747474, #4a4a4a);
  box-shadow: 0px 0px 20px #18296b;
  color: #fff;
  border: 2px solid #fff;
  margin: 30px;
}

.uploaded-pic {
  border: 2px solid #fff;
  border-radius: 4px;
  box-shadow: -3px 3px 8px #000;
  margin: 10px;
  height: 220px;
  width: 180px;
}

.relative {
  position: relative;
}

.none{
  display: none;
}

.over-loader {
  width: 100%;
  height: 100%;
  color: #fff;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #38333380;
}