.recent-bags-container {
  position: relative;
  display: flex;
  width: 90%;
  overflow: auto;
  margin: 1% 1%;
  padding: 1%;
  flex-direction: column;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background-color: #cacaca;
}

.recent-bags-dropdown {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0px;
  padding: 15px;
  width: 100%;
  height: 100%;
  border: 4px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background: linear-gradient(to right bottom, #18516b, #8f8792);
}

.recent-bags-images-box {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  border: 2px solid #fff;
  border-radius: 4px;
  box-shadow: -3px 3px 8px #000;
  margin: 1%;
  background: linear-gradient(to right bottom, #3c144834, #1a3a7579);
}

.recent-bags-img-box {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
}

.recent-bags-img {
  border: 2px solid #fff;
  border-radius: 4px;
  box-shadow: -3px 3px 8px #000;
  margin: 10px;
  height: auto;
  width: auto;
  max-height: 18vh;
}

.recent-bags-stain-hdr {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 0px 5px 2px;
  text-shadow: -3px 3px 6px #000;
  font-size: 14px;
}

.recent-bags-action-img-button {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
  color: #fff;
  background: none;
  border: none;
  cursor: inherit;
}

.recent-bags-snack {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

@media screen and (max-width: 900px) {
  .recent-bags-container {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .recent-bags-images-box {
    width: 48%;
    margin: 1%;
    padding: 2px;
  }

  .recent-bags-img {
    margin: 5px 3px;
    height: auto;
    width: auto;
    max-width: 48%;
  }

  .recent-bags-stain-hdr {
    font-size: 9px;
  }
}
