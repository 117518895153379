.admin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
}

.content-hide {
  max-height: 0px;
  width: 100%;
  transition: max-height 0.6s linear;
  overflow: hidden;
}

.content-show {
  max-height: 2200px;
  height: 100%;
  width: 100%;
  transition: max-height 0.7s linear;
}

.content-show-small {
  max-height: 700px;
  height: 100%;
  width: 100%;
  transition: max-height 0.7s linear;
  overflow: hidden;
}

.content-show-small.show-over {
  overflow: auto;
}

.header-buttons-collapse {
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #000000;
  padding: 6px 3%;
}

.header-buttons-collapse-sticky {
  z-index: 2;
  position: sticky;
  top: 0;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #000000;
  text-shadow: -2px 2px 5px #000;
  background: linear-gradient(to left top, #34113fe9, #21749a);
  padding: 6px 3%;
}

.update-user-button {
  height: fit-content;
  width: fit-content;
  margin: 4px;
  padding: 5px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  background: linear-gradient(to left top, #18516b, #8f8792);
  box-shadow: 0px 0px 8px #000000;
}

.admin-tasks-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  color: #fff;
  width: 95%;
  margin: 1% auto;
  padding: 1%;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 15px #000000;
  background: #1a3a75c5;
  background: linear-gradient(to right bottom, #3c1448ec, #1a3a75eb);
  overflow: hidden;
}

.admin-tasks-btns-and-display {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.admin-tasks-btns-box {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 60dvh;
  overflow: auto;
}

.admin-tasks-non-modal-display {
  display: flex;
  width: 40%;
  padding-right: 10px;
  flex-direction: column;
  align-items: flex-start;
  max-height: 60dvh;
  overflow-y: auto;
  overflow-x: hidden;
}

.admin-tasks-non-modal-display.no-task-display {
  align-items: center;
}

.admin-tasks-hdr-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.admin-task-item {
  display: flex;
  font-size: 12px;
  width: 95%;
  align-items: center;
  justify-content: space-evenly;
  margin: 4px auto;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -3px 3px 7px #000000;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
}

.admin-task-item.task-item-selected {
  background: linear-gradient(to right bottom, #1a3a75c5, #05f842b7);
}

.admin-task-item-info-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  padding: 0px 7px 0 0;
  margin: 0;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
}

.admin-page-action-img-button {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
  color: #fff;
  background: none;
  border: none;
  cursor: inherit;
  padding: 2px;
}

.admin-task-type-span {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: fit-content;
  padding-right: 20px;
  font-size: 14px;
}

.admin-task-type-span-note {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: start;
  width: 100%;
  font-size: 13px;
}

.admin-task-due-no-overflow {
  text-wrap: nowrap;
  white-space: nowrap;
  text-align: end;
  width: fit-content;
}

.admin-task-snack {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.admin-task-item-snack {
  width: fit-content;
  margin-right: 5px;
}

.admin-task-snack-btn {
  display: flex;
  width: fit-content;
  margin: 2px 4px;
  padding: 2px 5px;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
  background: #ffffff;
  color: #000000;
  border: 2px solid #000000;
  border-radius: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.admin-tasks-line {
  height: 1px;
  background-color: #fff;
  width: 95%;
  margin: 5px auto;
}

/* Scroll Shadow */
.shadow-wrapper {
  position: relative;
  border: 1px solid #d0d0d000;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
}

.shadow {
  position: absolute;
  left: 3%;
  z-index: 10;
  width: 94%;
  height: 40px;
  background-image: red;
  pointer-events: none;
}

.shadow--top {
  top: 0;
  background-image: radial-gradient(
    farthest-side at 50% 0%,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: #ffffff53;
}

.shadow--bottom {
  bottom: 0;
  background-image: radial-gradient(
    farthest-side at 50% 100%,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #ffffff53;
}

.admin-tasks-sort-box {
  width: 30%;
}

.client-tasks-modal-ul li {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}

.client-tasks-modal-li-marker {
  height: 6.1px;
  width: 6.1px;
  border-radius: 50%;
  display: inline-block;
  background-color: #fff;
  margin: auto 6px;
}

.client-tasks-modal-para {
  width: 95%;
  margin-block-start: 0;
  margin-block-end: 0;
}

.client-tasks-li-margin {
  margin-right: 5px;
}

.client-tasks-modal-cal-div {
  width: 90%;
}

.client-tasks-modal-search-div {
  display: flex;
  width: 100%;
  justify-content: center;
}

.client-tasks-modal-add-btn {
  color: #fff;
  z-index: 22;
  background: linear-gradient(to right bottom, #333333a4, #10085fd5);
  margin: 0 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  font-size: inherit;
  font-weight: inherit;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
}

.client-tasks-get-future-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #333333a4, #10085fd5);
  margin: 10px 5px 0px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  font-size: .9rem;
  font-weight: inherit;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
}

@media screen and (max-width: 900px) {
  .admin-tasks-btns-box {
    width: 100%;
  }

  .shadow-wrapper {
    width: 100%;
  }

  .admin-task-item {
    font-size: 13px;
  }
  .admin-tasks-sort-box {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .admin-task-item {
    font-size: 14px;
  }

  .admin-task-type-span-note {
    font-size: 12px;
  }
}
