.page-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  flex-direction: row;
  margin-bottom: 50px;
}

.section-header {
  width: 90%;
  margin: 10px 20px 20px;
  text-align: center;
  padding: 15px;
  background-color: #8a8a8a;
  color: #fff;
  text-shadow: -3px 3px 6px #000;
}

.upload-section-header {
  width: 90%;
  margin: 10px 20px 20px;
  text-align: center;
  padding: 15px;
  background-color: #8a8a8a;
  color: #fff;
  text-shadow: -3px 3px 6px #000;
  box-shadow: -10px 10px 30px #000000;
}

.hdr-test {
  text-align: center;
}

.quote-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-box {
  width: 45%;
  margin: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div .radio-form,
div .input-form {
  margin: 10px 0px;
  padding: 5px 10px;
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background: linear-gradient(to right bottom, #3c144884, #1a5875cd);
}

div .input-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.underline {
  text-decoration: underline;
}

.center-box {
  width: 100%;
  justify-content: center;
}

.form-labels {
  width: 100%;
  display: block;
  text-align: center;
  text-shadow: -2px 2px 2px #000;
  margin: 5px;
  font-size: 24px;
}

div #submit-button {
  width: fit-content;
  margin: 10px;
  padding: 8px;
  color: #fff;
  border: 4px solid #fff;
  border-radius: 5px;
  background: linear-gradient(to left top, #18516b, #8f8792);
  box-shadow: 0px 0px 20px #000000;
  transition: all 0.5s;
}
#submit-button:hover,
#submit-button:focus {
  padding: 8px 12px;
  color: #3a3a3a;
  background: linear-gradient(to right bottom, #a3b8a5, #ffffff);
}

.sticky-upload {
  position: sticky;
  position: -webkit-sticky;
  top: 20%;
}

.get-quote-phone-num-box {
  display: flex;
  width: 95%;
  justify-content: space-between;
}

.get-quote-text-consent-box {
  display: flex;
  height: fit-content;
  margin-bottom: 12px;
}

.quote-link-btn {
  border: none;
  background: none;
  color: #05F842;
  margin: 0;
  padding: 0 2px;
}

.quote-link-btn:hover {
  border-bottom: 1px solid #fff;
  color: #82fea1;
}

.quote-consent-box {
  display: flex;
  color: #ffffff;
  font-size: 14px;
  margin: 3px;
  padding: 0px 5px;
}

.quote-consent-words-box {
  flex-direction: column;
  display: flex;
}

.quote-priv-tos-links-box {
  display: flex;
  justify-content: center;
}

.quote-submit-consent {
  margin: 10px auto;
  display: flex;
  justify-content: center;
  width: 95%;
}
.quote-submit-consent-inner {
  display: flex;
  padding: 5px 10px;
  width: fit-content;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background: linear-gradient(to right bottom, #3c144884, #1a5875cd);
}

@media screen and (max-width: 900px) {
  div .radio-form,
  div .input-form {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .page-container {
    flex-direction: column;
    align-items: center;
  }

  .page-box {
    width: 95%;
    align-items: center;
  }

  div .radio-form,
  div .input-form {
    width: 100%;
    height: fit-content;
  }

  .form-labels {
    font-size: 17px;
  }
}
