.social-icons-container a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  margin: 0px 10px;
  color: #ffffff;
  transform: scale(1);
  transition: margin 0.1s linear, transform 0.1s linear, box-shadow 0.1s linear;
  padding: 30px;
  background-color: #00000081;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: -3px 3px 15px #000;
}

.social-icons-container a:hover {
  margin: 0px 16px;
  color: #05f842;
  transition: margin 0.1s linear, transform 0.1s linear, box-shadow 0.1s linear;
  transform: scale(1.1);
  border: 2px solid #05f842;
  box-shadow: -6px 6px 25px #000;
}

.social-icons-container a:active {
  margin: 0px 10px;
  color: #018522;
  transition: margin 0.1s linear, transform 0.1s linear;
  transform: scale(1);
  border: 2px solid #05f842;
}

.social-icons {
  width: 100%;
  display: flex;
  justify-content: center;
  min-width: fit-content;
  height: fit-content;
}

.social-icons-container {
  width: 98%;
  display: flex;
  justify-content: space-between;
  min-width: fit-content;
  height: fit-content;
  color: #fff;
  padding: 20px 10px;
  border-bottom: 2px #fff solid;
  border-top: 2px #fff solid;
  margin: 10px 0px;
  background: #ffffff2c;
  backdrop-filter: blur(2px);
  box-shadow: 0px 0px 25px #000;
}

@media screen and (max-width: 900px) {
  .social-icons-container a {
    width: 30px;
    height: 30px;
    transform: none;
    transition: none;
    padding: 24px;
    background-color: #00000081;
    border: 2px solid #fff;
    border-radius: 50%;
  }

  .social-icons-container a:hover {
    margin: 0px 10px;
    transition: none;
    transform: none;
  }

  .social-icons-container a:active {
    transition: none;
    transform: none;
  }
}

@media screen and (max-width: 600px) {
  .social-icons-container a {
    width: 22px;
    height: 22px;
    margin: 3px;
    transform: none;
    transition: none;
    padding: 19px;
    background-color: #00000081;
    border: 2px solid #fff;
    border-radius: 50%;
  }

  .social-icons-container a:hover {
    margin: 0px 5px;
    transition: none;
    transform: none;
  }

  .social-icons-container a:active {
    transition: none;
    transform: none;
  }
}
