.quote-invoice-edit-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  display: flex;
  width: 100%;
  height: 100dvh;
  align-items: center;
  justify-content: center;
  background-color: #0000007d;
  backdrop-filter: blur(3px);
  animation: quote-invoice-edit-modal-fader 0.2s linear forwards;
}

@keyframes quote-invoice-edit-modal-fader {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.quote-invoice-edit-modal-content {
  position: relative;
  z-index: inherit;
  height: 95%;
  width: 95%;
  max-width: 1200px;
  overflow: auto;
  padding: 20px;
}

.quote-invoice-edit-modal-close {
  z-index: 50;
  width: fit-content;
  color: #fff;
  margin: 1px 5px;
  padding: 1px 7px;
  position: sticky;
  top: 0;
  left: 90%;
  align-self: flex-start;
  background: #00000056;
  border: 1px #fff solid;
  border-radius: 20px;
  backdrop-filter: blur(2px);
}

.quote-invoice-edit-modal-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
}

.cust-quote-discount-info-box {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.form-updated {
  border-radius: 5px;
  border: 1px solid #1d9607fa;
  padding: 0 5px;
  background-color: #2ff90c85;
}