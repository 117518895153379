.pic-lookup-container {
  position: relative;
  display: flex;
  width: 90%;
  overflow: auto;
  margin: 1% 1%;
  padding: 1%;
  flex-direction: column;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background-color: #cacaca;
}

.pic-lookup-form {
  margin: 10px 0px;
  padding: 15px;
  width: 100%;
  height: 100%;
  border: 4px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background: linear-gradient(to right bottom, #18516b, #8f8792);
}

.img-by-id-box {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-by-id-img {
  margin: 10px 0px;
  padding: 20px;
  border: 2px solid #fff;
  border-radius: 4px;
  box-shadow: -3px 3px 8px #000;
  background: linear-gradient(to right bottom, #3c1448, #1a3a75);
  height: auto;
  width: auto;
  max-width: 60%;
  max-height: 80vh;
}

@media screen and (max-width: 900px) {
  .pic-lookup-container {
    width: 90%;
  }
}
