.trans-page {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.trans-page-container {
  display: flex;
  width: 95%;
  overflow: auto;
  margin: 10px auto;
  background: linear-gradient(to right bottom, #3c1448ec, #1a3a75ed);
  padding: 0 0 5px 0;
  flex-direction: column;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -5px 5px 8px #000;
  color: #fff;
  position: relative;
  backdrop-filter: blur(4px);
}

.trans-page-sticky-header {
    position: sticky;
    top: 0;
    width: 100%;
    text-align: center;
    background: linear-gradient(to right bottom, #3c1448ec, #1a3a75eb);
    padding: 4px 0;
    border-bottom: 1px solid #ffffff75;
    backdrop-filter: blur(3px);
    box-shadow: 0px 5px 18px #000;
    text-shadow: -2px 2px 3px #000;
}

.trans-item {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 7px 0;
  padding: 3px 0 3px 15px;
  flex-direction: column;
  color: #fff;
  border-bottom: 1px solid #ffffff75;
  border-top: 1px solid #ffffff75;
  justify-content: center;
  height: fit-content;
  min-height: 55px;
  text-shadow: -2px 2px 3px #000;
}

.trans-item:nth-of-type(odd) {
    background-color: #30303080;
}

.trans-item:nth-of-type(even) {
    background-color: #6363634e;
}


.trans-item-amount {
  font-size: 18px;
}

.trans-item-tip {
  color: #ffffffbf;
  font-size: 12px;
}

.trans-item-smaller {
  color: #ffffff;
  font-size: 12px;
}
