.add-inv-container {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 90%;
    margin: 1%;
    max-height: 95vh ;
    padding: 1%;
    flex-direction: column;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #000000;
    background-color: #cacaca;
  }
  
  .add-inv-content {
    max-height: 0px;
    width: 100%;
    transition: max-height 0.5s linear;
    overflow: hidden;
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .add-inv-content.show {
    max-height: 1200px;
    height: 100%;
    transition: max-height 0.5s linear;
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .add-inv-content-box {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100%;
    position: relative;
    background: linear-gradient(to right bottom, #313131b9, #500b69b3);
    backdrop-filter: blur(3px);
    margin: 15px 0px;
    padding: 15px;
    border: 1px solid #fff;
    border-radius: 3px;
    box-shadow: -3px 3px 10px #000;
    transition: all .4s linear;
  }
  
  .add-inv-content-box.success {
    display: flex;
    width: 90%;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin: 15px 0px;
    color: #05f842;
    font-weight: 700;
    text-shadow: -2px 2px 4px #05f842;
    box-shadow: -2px 2px 25px #05f842;
    border: 1px solid #05f842;
    background: linear-gradient(to right bottom, #18516b, #8f8792);
    transition: all .4s linear;
  }
  
  .add-item-btn {
    display: flex;
    min-width: 15%;
    width: fit-content;
    padding: 4px 5px;
    margin: 28px 0px 2px;
    justify-content: center;
    align-items: center;
    height: 100%;
    align-items: center;
    background: #4d4d4d;
    color: #fff;
    border: 2px solid #fff;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  
  .add-item-btn:hover {
    color: #05f842;
    border: 2px solid #05f842;
  }

  .add-item-btn-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  @media screen and (max-width: 900px) {
    .add-inv-container {
      width: 90%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .add-inv-container  {
      max-height: 70vh;
    }
  }
  