.view-invoice-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  display: flex;
  width: 100%;
  height: 100dvh;
  align-items: center;
  justify-content: center;
  background-color: #0000007d;
  backdrop-filter: blur(3px);
  animation: view-invoice-modal-fader 0.2s linear forwards;
}

@keyframes view-invoice-modal-fader {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.view-invoice-modal-content {
  position: relative;
  z-index: inherit;
  height: 95%;
  width: 95%;
  max-width: 1200px;
  overflow: auto;
  padding: 20px;
}

.view-invoice-modal-close {
  z-index: 50;
  width: fit-content;
  color: #fff;
  margin: 1px 5px;
  padding: 1px 7px;
  position: sticky;
  top: 0;
  left: 90%;
  align-self: flex-start;
  background: #00000056;
  border: 1px #fff solid;
  border-radius: 20px;
  backdrop-filter: blur(2px);
}

.view-invoice-modal-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
}

.view-invoice-modal-invoice-paid-opt-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  padding: 2px 5px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
}

.view-invoice-modal-invoice-paid-opt {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.view-quote-modal-invoice-paid-save {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 15px;
  margin: 5px 0;
}

@media screen and (max-width: 600px) {
  .view-invoice-modal-content {
    width: 95%;
    height: fit-content;
    max-height: 94dvh;
    padding: 0;
  }
}
