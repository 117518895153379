.air-datepicker-global-container {
  z-index: 2000;
}

.cal-time-select-container {
  display: flex;
  width: 100%;
  /* justify-content: ; */
  align-items: center;
  color: #fff;
}

.cal-time-sel-img-button {
  color: #fff;
  z-index: 22;
  background: transparent;
  margin: 5px 15px 5px 5px;
  padding: 2px;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  font-size: inherit;
  font-weight: inherit;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
}

.cal-time-sel-start-button {
  color: #fff;
  z-index: 22;
  background: linear-gradient(to right bottom, #333333a4, #10085fd5);
  margin: 5px auto;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  font-size: inherit;
  font-weight: inherit;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
}

.cal-el-input {
  color: #fff;
  max-width: 50%;
  padding: 3px 10px;
  width: fit-content;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
}
