.picture-upload-container {
  position: relative;
  display: flex;
  width: 90%;
  max-height: 80vh;
  overflow: auto;
  margin: 1% 1%;
  padding: 1%;
  flex-direction: column;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background-color: #cacaca;
}

.picture-upload-box {
  display: flex;
  width: 100%;
  justify-content: center;
}


.picture-upload-div {
  width: 45%;
  margin: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pic-id-text {
  font-size: 12px;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
}

.small-id-text {
  margin: 3px;
  width: 100%;
  color: #fff;
  font-size: 12px;
}

.checkbox-display {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkbox-row {
  display: flex;
  flex-direction: row;
}

.checkbox-labels {
  color: #fff;
  text-align: center;
  text-shadow: -2px 2px 2px #000;
  margin: 5px;
}

.uploaded-container {
  width: 100%;
  height: fit-content;
  background-color: #868585;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.uploaded-box {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

input[type="file" i] {
  display: none;
}

.custom-file-upload {
  width: fit-content;
  padding: 3px;
  background: linear-gradient(to right top, #747474, #4a4a4a);
  box-shadow: 0px 0px 20px #18296b;
  color: #fff;
  border: 2px solid #fff;
  margin: 5px;
}

.admin-uploaded-pic {
  border: 2px solid #fff;
  border-radius: 4px;
  box-shadow: -3px 3px 8px #000;
  margin: 10px;
  height: auto;
  width: auto;
  max-width: 90%;
  max-height: 60vh;
}

.pic-input-form {
  margin: 10px 0px;
  padding: 15px;
  width: 100%;
  height: 100%;
  border: 4px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background: linear-gradient(to right bottom, #18516b, #8f8792);
}

.pic-input-calendar-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-family: "Roboto","Helvetica","Arial",sans-serif;;
  margin: 10px 0px;
  padding: 15px;
  width: 100%;
  height: 100%;
  border: 4px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background: linear-gradient(to right bottom, #18516b, #8f8792);
}

.picture-upload-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
}

.relative {
  position: relative;
}

.none {
  display: none;
}

.over-loader {
  width: 100%;
  height: 100%;
  color: #fff;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #38333380;
}

.dark-button{
  height: fit-content;
  width: fit-content;
  margin: 4px;
  padding: 5px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  background: linear-gradient(to left top, #18516b, #8f8792);
  box-shadow: 0px 0px 8px #000000;
}

#admin-upload-button {
  width: fit-content;
  margin: 10px;
  padding: 8px;
  color: #fff;
  border: 4px solid #fff;
  border-radius: 5px;
  background: linear-gradient(to left top, #18516b, #8f8792);
  box-shadow: 0px 0px 20px #000000;
  transition: all 0.5s;
}
#admin-upload-button:hover,
#admin-upload-button:focus {
  padding: 8px 12px;
  color: #3a3a3a;
  background: linear-gradient(to right bottom, #a3b8a5, #ffffff);
}

.id-input {
  width: 100%;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  border: 2px solid #fff;
  font-size: 12px;
  border: 2px solid #fff;
  border-radius: 5px;
  background: linear-gradient(to left top, #18516b, #8f8792);
  box-shadow: 0px 0px 8px #000000;
}

.ba-ids {
  font-size: 14px;
  color: #fff;
  background: linear-gradient(to left top, #18516b, #8f8792);
  padding: 4px 8px;
  margin: 4px;
  border: #ffffff 2px solid;
  border-radius: 4px;
  box-shadow: -3px 3px 6px #000;
  text-align: center;
  height: fit-content;
  width: fit-content;
}

.picture-upload-small-text {
  width: 100%;
  text-align: center;
  font-size: 10px;
}

.picture-upload-assigned-client-box {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 900px) {
  .picture-upload-container {
    width: 90%;
    max-height: 70vh;
  }

  .pic-id-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .picture-upload-container {
    width: 90%;    
  }

  .pic-id-text {
    font-size: 10px;
  }

  .checkbox-row {
    display: flex;
    flex-direction: column;
  }

  .ba-ids {
    font-size: 8px;
    padding: 4px 2px;
    width: 100%;
    margin: 4px 2px;
  }
}