.ser-man-page {
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.ser-man-container {
  display: flex;
  width: 95%;
  margin: 1% auto;
  padding: 1%;
  flex-direction: column;
  align-items: center;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 30px #000000;
  backdrop-filter: blur(4px);
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
}

.ser-man-table-box {
  width: 98%;
  margin: 0 auto 8px;
  border: 1px solid #fff;
}

.ser-man-table-box tr td:first-of-type,
.ser-man-table-box tr th:first-of-type {
  padding-left: 10px;
  padding-top: 4px;
  text-align: start;
}

.ser-man-table-box tr td,
.ser-man-table-box tr th {
  margin: 0 4px;
  text-align: center;
}

.ser-man-table-box tr:nth-child(even) {
  background-color: #5251519c;
  padding: 2px 0;
  border-bottom: 1px solid #fff;
}

.ser-man-table-box tr:nth-child(odd) {
  background-color: #3737379c;
  padding: 2px 0;
  border-bottom: 1px solid #fff;
}

.ser-man-item-actions {
  display: flex;
  justify-content: center;
  text-align: end;
}

.ser-man-item-type {
  margin-right: 5px;
}

.ser-man-item-create-box {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  margin: 3px 0;
}

.ser-man-action-img-button {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
  color: #fff;
  background: none;
  border: none;
  cursor: inherit;
  padding: 0 1px;
}

.ser-man-green-text {
  color: #05f842;
}

.ser-man-red-text {
  color: #ff0202;
}

@media screen and (max-width: 900px) {
  .ser-man-item-info {
    width: fit-content;
    min-width: 48%;
    margin: 2px 1%;
    padding: 3px 0;
  }
}

@media screen and (max-width: 600px) {
  .ser-man-table-box tr td.ser-man-item-actions {
    justify-content: flex-end;
    text-align: end;
  }
}