.sent-email-page {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.sent-email-page-container {
  display: flex;
  width: 95%;
  margin: 1% auto;
  padding: 1%;
  flex-direction: column;
  align-items: center;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 30px #000000;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
}

.sent-email-page-filter {
  backdrop-filter: blur(3px);
}

.sent-email-info-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border: none;
  background: none;
  font: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.sent-email-item-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.sent-email-open-btns {
  display: flex;
  flex-direction: column;
  min-width: 20%;
  max-width: 100%;
  flex-grow: 1;
  width: -webkit-fill-available;
}

.sent-email-item {
  position: relative;
  background-color: #525151;
  color: #fff;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  width: 30%;
  max-width: 98%;
  margin: 2px auto;
  padding: 3px 7px;
  border: 1px solid #bdbbbb;
  border-radius: 5px;
  box-shadow: -3px 3px 6px #000000;
}

.sent-email-item-grn {
  color: #05f842;
}

.sent-email-has-attach.att-error {
  background-color: #c80404;
}

.sent-email-actions-box {
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1px;
}

.sent-email-page-action-img-button {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
  color: #fff;
  background: none;
  border: none;
  cursor: inherit;
}

.sent-email-actions-button-box {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: fit-content;
  margin: 12px 0 2px;
}

.sent-email-page-search-box {
  width: 95%;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

.sent-email-page-search-input {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-shrink: 1;
}

.sent-email-archive-button {
  color: #fff;
  z-index: 22;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
}

.sent-email-has-attach {
  display: block;
  white-space: nowrap;
  color: #ffffff;
  background-color: #188103;
  box-shadow: -2px 2px 4px #000;
  padding: 2px 8px;
  border-radius: 5px;
  margin: 5px;
  width: fit-content;
}

.sent-email-item-small-date {
  min-width: fit-content;
  margin: 0 4px;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
}

.email-item-small-date:nth-child(1) {
  margin-right: 4px;
}

@media screen and (max-width: 900px) {
  .sent-email-info-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .sent-email-item-small-date {
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .email-item {
    width: 60%;
    flex-direction: column;
  }
  .sent-email-info-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .email-item-small-date {
    justify-content: flex-start;
    width: 100%;
    margin: 0;
  }

  .email-actions-box {
    width: 100%;
    justify-content: flex-start;
    margin: 3px;
  }

  .email-actions-button-box {
    flex-wrap: wrap;
    font-size: 10px;
  }
}
