.keap-merge-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
}

.keap-merge-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1%;
  margin: 10px 0;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -6px 6px 10px #000000;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
  backdrop-filter: blur(10px);
  width: 95%;
  color: #fff;
}

.keap-merge-input-box {
  display: flex;
  width: 100%;
  margin: 5px 0;
  justify-content: space-around;
}

.keap-merge-btn-box {
  display: flex;
  width: 90%;
  margin: 10px 0;
  justify-content: space-between;
}

.keap-merge-err-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-height: 50dvh;
  overflow: auto;
  margin: 5px 0;
}

.keap-merge-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
}

.keap-merge-btn-small-anchor {
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
  font-size: 12px;
}

.keap-merge-btn-small-anchor:hover {
  color: #05F842;
}

.keap-merge-btn.disabled {
  background: linear-gradient(to right bottom, #333333c6, #191634dd);
}

.keap-merge-err-item {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 7px 0;
  padding: 3px 0 3px 15px;
  color: #fff;
  border-bottom: 1px solid #ffffff75;
  border-top: 1px solid #ffffff75;
  justify-content: center;
  align-items: center;
  height: fit-content;
  text-shadow: -2px 2px 3px #000;
}

.keap-merge-err-item:nth-of-type(odd) {
  background-color: #30303080;
}

.keap-merge-err-item:nth-of-type(even) {
  background-color: #6363634e;
}

.keap-merge-overlay-circle-box {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
  pointer-events: none;
  top: 0;
  left: 0;
  background-color: #00000071;
  backdrop-filter: blur(3px);
}
