.create-client-container {
    position: relative;
    display: flex;
    width: 90%;
    overflow: auto;
    margin: 1% 1%;
    padding: 1%;
    flex-direction: column;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #000000;
    background-color: #cacaca;
    color: #fff;
    overflow: auto;
    max-height: 90dvh;
  }
  
  .create-client-form {
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 15px;
    width: 100%;
    border: 4px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #000000;
    background: linear-gradient(to right bottom, #18516b, #8f8792);
  }

  .create-client-status-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    color: #fff;
  }

  .create-client-add-phones-btn-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .create-client-phone-box {
    display: flex;
    color: #fff;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 2px auto;
    width: 95%;
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #000000;
    background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
  }

  .create-client-errors-box {
    width: 100%;
    display: flex;
    height: fit-content;
    flex-direction: column;
    align-items: flex-start;
    margin: 5px 2%;
  }
  
  .create-client-btn {
    color: #fff;
    background: linear-gradient(to right bottom, #3333337d, #10085fd5);
    margin: 5px;
    padding: 2px 8px;
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: -2px 2px 4px #000;
    width: fit-content;
    height: fit-content;
  }

  .create-client-btn.success {
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: -2px 2px 6px #05F842;
    background: linear-gradient(to left bottom, #05F842, #10085fd5);
  }

  .create-client-paste-note-box {
    display: flex;
    width: 95%;
  }

  @media screen and (max-width: 900px) {
    .create-client-container {
        width: 95%;
    }
  }
  