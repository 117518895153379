.msg-page-texts-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  color: #fff;
  width: 95%;
  margin: 1% auto;
  padding: 1%;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 15px #000000;
  background: #1a3a75c5;
  background: linear-gradient(to right bottom, #3c1448ec, #1a3a75eb);
  overflow: hidden;
}

.msg-page-texts-hdr-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.msg-page-texts-text-and-num-box {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.msg-page-texts-mes-box {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: none;
  /* background-color: #4b4b4b;*/
  border-radius: 4px;
  padding: 0 8px 8px;
}

.msg-page-texts-mes-box-out {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.msg-page-texts-mes-box-inc {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.msg-page-texts-mes-box-out.mes-box-scale {
  transform-origin: bottom right;
  transform: scale(0);
  animation: scaleUp 0.15s ease-in forwards;
}

.msg-page-texts-mes-box-inc.mes-box-scale {
  transform-origin: bottom left;
  transform: scale(0);
  animation: scaleUp 0.15s ease-in forwards;
}

.msg-page-texts-mes-item {
  display: flex;
  max-width: 80%;
  min-width: fit-content;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 3px 12px;
  margin: 2px 0;
  border-radius: 10px;
  white-space: pre-wrap;
}

.msg-page-texts-mes-item-out {
  background-color: #05ac05;
  box-shadow: 3px 3px 6px #000;
}

.msg-page-texts-mes-item-inc {
  width: 100%;
  display: flex;
  background-color: #4e4e4f;
  box-shadow: -3px 3px 6px #000;
}

.msg-page-texts-mes-item-date {
  font-size: 9px;
}

.msg-page-texts-mes-item-single-img-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  position: relative;
  height: 190px;
  margin: 6px 0;
  /* animation: scaleUpBox .5s ease-in forwards; */
}

.msg-page-text-img {
  max-width: 90%;
  max-height: 100%;
  margin: 0;
  border: 1px solid #fff;
  box-shadow: -3px 3px 5px #000;
  border-radius: 8px;
  /* transform-origin: top center;
    transform: scale(0, 0);
    animation: scaleUp .5s ease-in forwards; */
}

@keyframes scaleUp {
  0% {
    transform: scale(0);
  }
  90% {
    transform: scale(1.05) rotate(0turn);
  }
  91% {
    transform: rotate(0.01turn);
  }
  100% {
    transform: scale(1) rotate(0turn);
  }
}

@keyframes scaleUpBox {
  0% {
    height: 0px;
  }
  100% {
    height: 190px;
  }
}

.msg-page-texts-messenger-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid #fff;
}

/* messages hover scroll btns */
.msg-page-texts-hvr-scroll {
  position: absolute;
  left: 3%;
  z-index: 14;
  width: 93%;
  height: 30px;
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0z" fill="none"/><path stroke="white" fill="white" d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"/></svg>')
      14 10,
    default;
  border: none;
  background: none;
}

.msg-page-texts-hvr-scroll-top {
  top: 0;
}

.msg-page-texts-hvr-scroll-top:hover {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0z" fill="none"/><path stroke="white" fill="white" d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"/></svg>')
      14 10,
    default;
}

/* <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/></svg> */

.msg-page-texts-hvr-scroll-bottom:hover {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0z" fill="none"/><path stroke="white" fill="white" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/></svg>')
      14 10,
    default;
}

.msg-page-texts-hvr-scroll-bottom {
  bottom: 0;
}

/* Scroll Shadow */
.msg-page-texts-shadow-wrapper {
  position: relative;
  border: 1px solid #d0d0d000;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
}

.msg-page-texts-shadow {
  position: absolute;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 30px;
  background-image: red;
  pointer-events: none;
}

.msg-page-texts-shadow--top {
  top: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(47, 20, 72, 0.6) 60%,
    rgba(26, 58, 117, 0) 100%
  );
  /* background-image: (to bottom, rgba(47, 20, 72, 0.925), rgba(26, 58, 117, 0.922)); */
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: #ffffff53;
}

.msg-page-texts-shadow--bottom {
  bottom: 0;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(47, 20, 72, 0.6) 60%,
    rgba(26, 58, 117, 0) 100%
  );
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #ffffff53;
}
/* End shadow controls */

.msg-page-texts-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
}

.msg-page-texts-btn-smaller {
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  font-size: 12px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
}

.msg-page-texts-btn-copy {
  /* position: absolute;
  right: -20px; */
  color: #fff;
  background: linear-gradient(to right bottom, #05f842c8, #1a3a75);
  margin: 0 auto 0 10px;
  padding: 2px 8px;
  font-size: 12px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
}

.msg-page-texts-btn.send-text-dis {
  background: linear-gradient(to right bottom, #555555cb, #c0bfbfd5);
}

.msg-page-none {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .msg-page-texts-shadow-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
}
