.blocked-emails-page {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.blocked-emails-page-container {
  display: flex;
  width: 95%;
  margin: 1% auto;
  padding: 1%;
  flex-direction: column;
  align-items: center;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 30px #000000;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
}

.blocked-emails-totals-box {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.blocked-emails-ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  padding: 0;
  margin: 0px auto;
}

.blocked-emails-li {
  padding: 0;
  margin: 2px auto;
  width: 45%;
  height: fit-content;
  border-bottom: 1px solid #ffffff;
}

@media screen and (max-width: 600px) {
  .blocked-emails-ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    margin: 0px auto;
  }

  .blocked-emails-li {
    padding: 0;
    margin: 2px 5px;
    width: 90%;
  }
}
