.home-box {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.home-info {
    margin: 15px;
    padding: 10px;
    width: 50%;
    background: linear-gradient(to right bottom,#3c1448a5, #1a5875b0);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    box-shadow: -10px 10px 30px #000000;
    backdrop-filter: blur(3px);
}

.center-text {
    text-align: center;
}

.text-shadow {
    text-shadow: -3px 3px 7px #000;
}

.home-info-hdr {
    color: #05F842;
    font-family: "Noto", serif;
}

.logo-img {
    height: 150px;
    width: 150px;
    margin-right: 1em;
    float: left;
    background-color: #f7f7f7;
    box-shadow: 0px 0px 8px #000000;
    border-radius: 8px 8px;
}

.info-section {
    margin: 10px 0;
}

.home-image-box {
    display: flex;
    justify-content: center;
    width: 450px;
}

.small-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #8a8a8a;
    margin: 15px;
    border-radius: 5px;
    color: #fff;
    text-shadow: -3px 3px 7px #000;
    height: fit-content;
}

.small-list li h1 {
    text-align: center;
    color: #05F842;
}

.small-list li {
    margin: 10px;
}

.tag-line-box #make-visible{
    justify-content: center;
    display: flex;
    width: 95%;
    transition: 1s filter linear, opacity 1s linear;
    -webkit-transition: 1s -webkit-filter linear, opacity 1s linear;
    -moz-transition: 1s -moz-filter linear, opacity 1s linear;
    -ms-transition: 1s -ms-filter linear, opacity 1s linear;
    -o-transition: 1s -o-filter linear, opacity 1s linear;
    opacity: 1;
    filter: blur(0px);
    animation: sliderVisLeft 1s ease-in-out forwards;
}

@keyframes sliderVisLeft {
    0% {translate: -50%}
  100% {translate: 0%}
}

.tag-line-box-invis {
    filter: blur(40px);
    opacity: .01;
    position: relative;
    justify-content: center;
    display: flex;
    width: 95%;
}

.home-absolute-box {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    z-index: 0;
}

.home-absolute-box:before {
    content: '';
    display: block;
    width: 100%;
    height: 70px;
    position: absolute;
    overflow: hidden;
    background-color: #37db003b;
    box-shadow: 0px 0px 20px #000000;
    backdrop-filter: blur(3);
    top: 50%;
    left: 0;
    z-index: -1;
    -ms-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.tag-line-header {
    color: #ffffff;
    font-size: 3rem;
    text-shadow: -3px 3px 7px #000;
    text-align: center;
    margin: 30px 15px;
    padding: 10px;
    width: 90%;
    background: linear-gradient(to right bottom,#34123fd7, #1e6788da);
    box-shadow: 0px 0px 40px #000000;
    border-radius: 5px;
    backdrop-filter: blur(4px);
}

@media screen and (max-width: 900px) {
    .home-box {
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-items: center;

    }

    .home-image-box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .home-info {
        width: 90%;
    }
}

@media screen and (max-width: 600px) {
    .home-box {
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-items: center;
    }

    .home-info {
        width: 90%;
    }

    .home-image-box {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .tag-line-header {
        font-size: 2rem;
        background: linear-gradient(to right bottom,#34123f82, #1e66887b);
    }

    .tag-line-box-invis::after {
        width: 100%;
    }
}