.single-client-page {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.single-client-page-container {
  display: flex;
  color: #fff;
  width: 95%;
  height: fit-content;
  margin: 1% auto;
  padding: 1%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 30px #000000;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
}

.single-client-info-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 1%;
  margin: 0 1%;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 30px #000000;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
  backdrop-filter: blur(10px);
  width: 40%;
}

.single-client-functions-box {
  display: flex;
  flex-direction: column;
  margin: 0 1%;
  width: 56%;
}

.single-client-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
}

.single-client-btn.success {
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 6px #05f842;
  background: linear-gradient(to left bottom, #05f842, #10085fd5);
}

input[type="text"]:disabled {
  color: #fff !important;
}

.single-client-add-phones-btn-box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.single-client-phone-box {
  display: flex;
  color: #fff;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 2px auto;
  padding: 0 10px;
  width: 90%;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 14px #000000;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
}

.single-client-phone-box-selected {
  background: linear-gradient(to right bottom, #05f842, #1a3a75c5);
}

.single-client-type-span {
  margin: 0 auto;
  height: fit-content;
  min-width: fit-content;
}

.single-client-unread-span {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff0101ce;
  padding: 0 4px;
  border-radius: 5px;
  border: 1px solid #fff;
  text-align: center;
}

.single-client-errors-box {
  width: 100%;
  display: flex;
  height: fit-content;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 2%;
}

.single-client-note-btn-box {
  display: flex;
  width: 100%;
  margin: 0 5%;
  max-height: 0px;
  justify-content: flex-end;
  opacity: 0.01;
  transition: all 0.3s linear;
  overflow: hidden;
}

.single-client-note-btn-box.show-note-btn {
  max-height: 50px;
  height: fit-content;
  opacity: 1;
  transition: all 0.3s linear;
}

.single-client-actions-btn {
  z-index: 2;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #000000;
  text-shadow: -2px 2px 5px #000;
  background: linear-gradient(to left top, #34113fe9, #21749a);
  padding: 6px 3%;
}

.single-client-actions-btn-box {
  position: relative;
  display: flex;
  width: 99%;
  overflow: auto;
  margin: 1% auto;
  padding: 3px;
  flex-direction: column;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background-color: #cacaca;
  color: #fff;
  overflow: auto;
  max-height: 80dvh;
}

.single-client-copy-btn-box {
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: flex-start;
  margin: 2px auto;
  font-size: 12px;
}

.single-client-copy-btn {
  background: linear-gradient(to right bottom, #05f842c8, #1a3a75);
  font-weight: 800;
  color: inherit;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 2px 4px;
  height: fit-content;
  width: fit-content;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.single-client-copy-btn.phn-copy-btn {
  box-shadow: 0px 0px 20px #000000;
  margin: 0 10px 0 0;
}

@media screen and (max-width: 900px) {
  .single-client-phone-box {
    justify-content: space-between;
  }

  .single-client-actions-btn-box {
    width: 95%;
  }
}

@media screen and (max-width: 600px) {
  .single-client-page-container {
    align-items: center;
  }

  .single-client-info-container {
    width: 96%;
    margin: 3% 1%;
  }

  .single-client-functions-box {
    margin: 1%;
    width: 98%;
  }
}
