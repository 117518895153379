/* modal displays */
.sign-modal-show {
  z-index: 1100;
  display: block;
  height: 100dvh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: itemModalFader 0.3s linear forwards;
}

.sign-modal-hidden {
  display: none;
}

.sign-update-item-modal-bg {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right bottom, #0343617a, #500b6980);
}

.sign-update-hdr {
  color: #fff;
  font-weight: 500;
  text-shadow: -3px 3px 5px #000;
}

@keyframes itemModalFader {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.sign-update-item-modal-box {
  color: #fff;
  z-index: 8;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  min-width: fit-content;
  min-height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(to right bottom, #313131b9, #500b69b3);
  padding: 15px;
  border: 1px solid #fff;
  border-radius: 3px;
}

.sign-update-item-modal-hdr {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sign-update-modal-item-btn {
  display: flex;
  min-width: 25%;
  width: fit-content;
  padding: 8px;
  justify-content: center;
  height: 100%;
  align-items: center;
  background: #4d4d4d;
  color: #fff;
  border: 2px solid #fff;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.sign-update-modal-item-btn:hover {
  color: #05f842;
  border: 2px solid #05f842;
}

@media screen and (max-width: 900px) {
  .sign-update-modal-item-btn {
    padding: 15px;
  }

  .sign-update-item-modal-box {
    color: #fff;
    z-index: 8;
    position: absolute;
    width: 90%;
    height: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to right bottom, #313131b9, #500b69b3);
    padding: 15px;
    border: 1px solid #fff;
    border-radius: 3px;
  }
}
