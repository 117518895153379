.keap-file-merge-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
}

.keap-file-merge-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1%;
  margin: 10px 0;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -6px 6px 10px #000000;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
  backdrop-filter: blur(10px);
  width: 95%;
  color: #fff;
}

.keap-file-merge-input-box {
  display: flex;
  width: 100%;
  margin: 5px 0;
  justify-content: space-around;
}

.keap-file-merge-img-box {
    display: flex;
    max-width: 80dvw;
    max-height: 60dvh;
    margin: 10px 0;
    padding: 15px;
    color: #fff;
    border: 1px solid #ffffff;
    box-shadow: -2px 2px 3px #000;
  }

.keap-file-merge-img {
    max-width: 80dvw;
    max-height: 60dvh;
    margin: 10px auto;
    padding: 0px;
    border: 2px solid #ffffff;
    box-shadow: -4px 4px 8px #000;
  }

.keap-file-merge-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
}