.sign-assignment-container {
  display: flex;
  width: 90%;
  max-height: 90vh;
  overflow: auto;
  margin: 1%;
  padding: 1%;
  min-height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background-color: #cacaca;
}

.sign-assignment-box {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  padding: 2px 4px;
  margin: 7px 0px;
  color: #fff;
  font-weight: 500;
  text-shadow: -2px 2px 4px #000;
  box-shadow: -2px 2px 5px #000;
  border: 1px solid #000;
  transform: scale(1);
  background: linear-gradient(to right bottom, #18516b, #8f8792);
  transition: all 0.4s linear;
}

.sign-assignment-box.success {
  color: #05f842;
  transform: scale(0.97);
  text-shadow: -2px 2px 4px #05f842;
  box-shadow: -2px 2px 25px #05f842;
  border: 1px solid #05f842;
  background: linear-gradient(to right bottom, #18516b, #8f8792);
  transition: all 0.4s linear;
}

.sign-list-box {
  display: flex;
  flex-direction: column;
  min-width: 30%;
}

.sign-list-item {
  width: fit-content;
  min-width: 100%;
}

.sign-list-box-confirm {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.sign-list-item-confirm {
  width: fit-content;
}

.sign-func-box {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  justify-content: space-between;
}

.sign-delete-btn {
  display: flex;
  width: 100%;
  margin: 2px;
  padding: 2px 5px;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
  background: #ffffff;
  color: #000000;
  border: 2px solid #000000;
  border-radius: 10px;
  box-shadow: -2px 2px 5px #000;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.sign-new-btn-box {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 15px 0px 0px;
}

.sign-new-btn {
  padding: 2px 7px;
  font-weight: 700;
  background: linear-gradient(to right bottom, #3c144884, #1a5875cd);
  color: #ffffff;
  border: 2px solid #000000;
  border-radius: 10px;
  box-shadow: -2px 2px 5px #000;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.sign-delete-btn.confirm {
  width: fit-content;
  margin: 2px 4px;
}

@media screen and (max-width: 900px) {
}
