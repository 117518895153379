.create-quote-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1180;
  display: flex;
  width: 100%;
  height: 100dvh;
  align-items: center;
  justify-content: space-evenly;
  background-color: #0000007d;
  backdrop-filter: blur(5px);
  animation: create-quote-modal-fader 0.2s linear forwards;
}

@keyframes create-quote-modal-fader {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.create-quote-modal-content {
  position: relative;
  color: #000000;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
  padding: 2%;
  margin: 0 auto;
  z-index: 5;
  max-height: 95dvh;
  height: fit-content;
  min-width: 65%;
  width: fit-content;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 30px #000000;
  overflow: auto;
}

.create-quote-page {
  width: 95%;
  max-width: 900px;
  position: relative;
  margin: 15px auto;
  padding: 15px 5px;
  display: flex;
  flex-direction: column;
  border: 2px solid #000000;
  border-radius: 5px;
  box-shadow: -5px 3px 10px #000000;
  background: #fffffff8;
}

.create-quote-modal-header {
  text-decoration: underline;
  white-space: nowrap;
  padding: 8px 10px;
  background: linear-gradient(to right bottom, #34113fcc, #2a8ebde2);
  color: #fff;
  text-shadow: -6px 6px 12px #000;
  border-radius: 6px;
  border: 1px solid #fff;
  width: fit-content;
  height: fit-content;
}

.create-quote-start-button {
  color: #fff;
  z-index: 22;
  background: linear-gradient(to right bottom, #333333a4, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  font-size: inherit;
  font-weight: inherit;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
}

.create-quote-save-button {
  color: #fff;
  background: linear-gradient(to right bottom, #333333a4, #10085fd5);
  margin: 2px 4px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  font-size: inherit;
  font-weight: inherit;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
}

.create-quote-modal-close-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: transparent;
  height: fit-content;
}

.create-quote-new-item-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}

.create-quote-new-item-save {
  display: flex;
  width: 90%;
  justify-content: flex-end;
  margin: 5px auto;
}

.create-quote-totals-box div:last-of-type {
  border-top: 1px solid #000;
  font-size: 25px;
}

.cust-quote-items-item span span.create-quote-item-rmv-btn-wrapper {
  position: relative;
  background-color: #de0303;
  border: 1px solid #000;
  border-radius: 9px;
  box-shadow: -1px 1px 3px rgb(165, 165, 165);
  color: #fff;
  height: 16px;
  width: 16px;
  max-width: 16px;
  margin: auto 3px;
  padding: 0px 0px 0 0px;
  text-align: center;
}

.cust-quote-items-item
  span
  span.create-quote-item-rmv-btn-wrapper
  button.create-quote-item-rmv-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  margin: 0;
  padding: 0 0 0 3px;
  font-size: 12px;
  transform: translateY(-50%);
  background: none;
  border: none;
  text-align: center;
  color: #fff;
}

.cust-quote-items-item span span.create-quote-item-name {
  display: inline;
  width: 80%;
  text-wrap: wrap;
}

.create-quote-err-box {
  max-width: 95%;
  width: fit-content;
  display: flex;
  height: fit-content;
  flex-direction: column;
  align-items: flex-start;
  background: #e83535;
  margin: 1px auto 10px;
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: -2px 2px 6px #3e3e3e;
  color: #fff;
}

.create-quote-err-box ul{
  padding: 0 8px 0 22px;
  margin: 0;
}

.create-quote-btn-err {
  background: #de0303;
}

.create-quote-save-btns-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 15px;
}

.cust-quote-page-assignee-box {
  display: flex;
  width: 95%;
  margin: 3px auto;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .create-quote-modal-header {
    font-size: 26px;
    margin: 5px;
    width: 100%;
    text-align: center;
    border-radius: 0px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    border-left: none;
    border-right: none;
    padding: none;
    margin: none;
  }

  .cust-quote-items-item
    span
    span.create-quote-item-rmv-btn-wrapper
    button.create-quote-item-rmv-btn {
    padding: 0 0 0 4px;
  }

  .create-quote-save-btns-box {
    align-items: flex-end;
    flex-direction: column;
    padding-bottom: 10px;
  }
}
