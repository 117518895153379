@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");

body {
  font-size: 16px;
  min-width: 320px;
  width: 100%;
  height: 100%;
  max-width: 1700px;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 38px;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 30px;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 24px;
  margin: 0;
  padding: 0;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start;
  width: 100%;
}

.page-header {
  text-align: center;
  margin: 20px;
  padding: 15px;
  background: linear-gradient(to right bottom, #34113fcc, #2a8ebde2);
  color: #fff;
  text-shadow: -6px 6px 12px #000;
  border-radius: 6px;
  border: 2px solid #fff;
  width: fit-content;
}

.green-color-text {
  color: #05f842;
}

.close-modal-x-btn {
  color: #ffffff;
  height: 22px;
  padding: 4px 6px 5px 5px;
  box-shadow: 0px 0px 10px #000;
  background: #a90303;
  border: none;
  line-height: 22px;
  font-size: 20px;
  margin: 0 3%;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

::-webkit-scrollbar {
  width: 20px;
  background: linear-gradient(to right bottom, #3d134aec, #213252eb);
  box-shadow: inset 0px 0px 8px  #ded9d9;
}

::-webkit-scrollbar-track {
  /* background: linear-gradient(to right bottom, #3d134aec, #213252eb); */
  background: none;
  border-radius: 10px;
  margin: 8px;
  /* border: 1px solid #fff; */
  border: 3px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb {
  background: #05f842b7;
  border-radius: 10px;
  border: 7px solid transparent;
  background-clip: padding-box;
}

@media screen and (max-width: 600px) {
  body {
    font-size: 14px;
  }

  h1 {
    font-size: 22px;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 19px;
    margin: 0;
    padding: 0;
  }

  h3 {
    font-size: 17px;
  }

  .close-modal-x-btn {
    color: #ffffff;
    height: 20px;
    padding: 9px 5px 10px 5px;
    box-shadow: 0px 0px 10px #000;
    background: #a90303;
    border: none;
    line-height: 20px;
    font-size: 16px;
    margin: 0 3%;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
