.sent-email-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1110;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #0000007d;
    backdrop-filter: blur(3px);
    animation: sent-email-modal-fader 0.2s linear forwards;
  }
  
  @keyframes sent-email-modal-fader {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }
  
  .sent-email-modal-content {
    position: relative;
    color: #fff;
    background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
    width: 85%;
    padding: 10px;
    z-index: 15;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border: 2px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 30px #000000;
    max-height: 90vh;
    overflow: auto;
  }
  
  .sent-email-modal-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .sent-email-modal-list li {
    background-color: #525151;
    color: #fff;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    margin: 15px auto;
    padding: 10px;
    border: 1px solid #bdbbbb;
    border-radius: 5px;
    box-shadow: -3px 3px 6px #000000;
    overflow-wrap: anywhere;
  }
  
  .sent-email-modal-close {
    z-index: 50;
    width: fit-content;
    color: #fff;
    margin: 1px 5px;
    padding: 1px 7px;
    position: sticky;
    top: 0;
    right: 0;
    align-self: flex-end;
    background: #00000056;
    border: 1px #fff solid;
    border-radius: 20px;
    backdrop-filter: blur(2px);
  }
  
  .sent-email-modal-images-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    height: fit-content;
    border: 2px #fff solid;
    border-radius: 10px;
    background: linear-gradient(to right bottom, #929292bf, #1a3a75e7);
    box-shadow: -5px 5px 8px #000000;
  }
  
  .sent-email-modal-images-box {
    width: 45%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1%;
    margin: 10px;
    background: #555555;
    box-shadow: -3px 3px 8px #000;
    border-radius: 8px;
    border: 1px solid #fff;
  }
  
  .sent-email-modal-pdf-box {
    width: fit-content;
    max-width: 95%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1%;
    margin: 10px;
    background: #555555;
    box-shadow: -3px 3px 8px #000;
    border-radius: 8px;
    border: 1px solid #fff;
  }
  
  .sent-email-modal-pdf-file {
    display: flex;
    height: fit-content;
    width: 98%;
    flex-wrap: wrap;
    margin: 5px;
    justify-content: center;
    padding: 1%;
    box-shadow: -3px 3px 8px #000;
    border-radius: 4px;
    border: 1px solid #060076;
  }
  
  .page-header.sent-email-modal-img-hdr {
    width: 90%;
    margin: 15px auto;
    padding: 8px;
  }
  
  .sent-email-modal-img-hdr-box {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .sent-email-modal-img {
    width: auto;
    max-width: 95%;
    max-height: 70vh;
    height: auto;
    border: 2px #fff solid;
    border-radius: 5px;
    box-shadow: -5px 5px 10px #000000;
    margin: 5px;
  }
  
  .sent-email-modal-copy-btn {
    background: linear-gradient(to right bottom, #05f842c8, #1a3a75);
    font-weight: 800;
    color: inherit;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 2px 4px;
    height: fit-content;
    width: fit-content;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  
  @media screen and (max-width: 900px) {
    .sent-email-modal-img-hdr-box {
      justify-content: space-between;
    }
  
    .sent-email-modal-images-box .sent-email-modal-img-hdr-box h5.page-header {
      padding: 8px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .sent-email-modal-container {
      align-items: flex-start;
      margin-top: 3dvh;
    }
  
    .sent-email-modal-images-container {
      flex-direction: column;
      align-items: center;
    }
  
    .sent-email-modal-img-hdr-box {
      justify-content: space-evenly;
    }
  
    .sent-email-modal-images-box {
      width: 95%;
      padding: 1%;
      margin: 5px auto;
    }
  
    .sent-email-modal-content {
      width: 90%;
      height: fit-content;
      max-height: 94dvh;
    }
  }
  