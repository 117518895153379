.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}
 
.loader-content {
  position: absolute;
  z-index: 40;
  color: #ffffff;
}

.loader-content h3 {
  opacity: 1;
  animation: flutter 1s linear infinite;
  margin: 10px;
}

@keyframes flutter {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.bdr-spinner::after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 130px;
  height: 130px;
  border-radius: inherit;
  border: inherit;
  background: inherit;
  inset: -3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bdr-spinner {
  z-index: 2;
  color: #ffffff;
  position: relative;
  width: 130px;
  height: 130px;
  border-radius: 10rem;
  border: 3px solid white;
  background: #646464;
}

.bdr-spinner::before {
  z-index: 0;
  position: absolute;
  content: "";
  background: linear-gradient(#23c00b, #0e1badaa);
  width: 160px;
  height: 160px;
  border-radius: 10rem;
  filter: blur(5px);
  /* border: 3px solid white; */
  /* box-shadow: inset 0px 0px 100px -70px #111; */
  animation: spin 1s linear infinite;
  inset: -18px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
