div .helper-text {
    color: #fff;
    font-size: .85em;
    background-color: #b17532;
    width: fit-content;
    border: 1px solid #fff;
    border-radius: 12px;
    padding: 2px 7px;
    margin: 4px 0px;
}

div .helper-text.noBG {
    background-color: transparent;
    color: #fff;
    font-size: .85em;
    width: fit-content;
    border: none;
    padding: 2px 7px;
    margin: 4px 0px;
}