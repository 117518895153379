.carousel {
  width: 100%;
  height: fit-content;
}

.our-doors-carousel-container {
  position: relative;
  width: 80%;
  min-height: 250px;
  margin: 20px;
  border: 4px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background: linear-gradient(to right bottom,#3c144834, #1a3a7579);
  backdrop-filter: blur(2px);
  margin: 14px 0;
}

.our-doors-week-card {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.our-doors-flex-container {
  display: flex;
  height: fit-content;
  width: fit-content;
  max-height: 65vh;
  max-width: 70%;
  justify-content: center;
  align-items: center;
}

.our-doors-carousel-img {
  height: auto;
  width: auto;
  max-width: 70%;
  max-height: 90%;
  border: 3px solid #000;
  margin: 10px;
}

.our-doors-img-box {
  width: 49%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.our-doors-hdr {
  z-index: 1;
  margin: 10px;
  width: fit-content;
  text-align: center;
  color: #fff;
  text-shadow: -3px 3px 5px #000;
  padding: 15px 10px;
  background: #0000008a;
  box-shadow: -3px 3px 5px #333333;
  border-radius: 5px;
}

.our-doors-before-after {
  z-index: 1;
  width: fit-content;
  padding: 6px;
  position: absolute;
  top: 80%;
  color: #fff;
  text-shadow: -3px 3px 5px #000;
  background: #000000a7;
  box-shadow: 0px 0px 30px #333333;
  border-radius: 5px;
}

.our-doors-ul {
  color: #fff;
  font-weight: 600;
  height: fit-content;
  width: fit-content;
  list-style: none;
  margin: 15px;
  padding: 20px;
  line-height: 1.6rem;
  background: linear-gradient(to right bottom, #18516b, #8f8792);
  border: 3px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
}

@media screen and (max-width: 900px) {
  .our-doors-carousel-container {
    display: flex;
    align-items: center;
    height: fit-content;
    width: 90%;
    margin: 2%;
  }

  .our-doors-flex-container {
  max-height: 100%;
  max-width: 90%;
  }

  .our-doors-carousel-img {
    border: 3px solid #000;
    margin: 7px;
    height: auto;
    width: auto;
    max-width: 99%;
    max-height: 80%;
  }

  .our-doors-week-card {
    height: fit-content;
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .our-doors-carousel-container {
    display: flex;
    align-items: center;
    justify-self: center;
    width: 90%;
    min-height: 400px;
    margin: 3%;
  }
}
