.privacy-tos-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.privacy-tos-content {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 10px 0;
  padding: 10px;
  align-items: flex-start;
  background: linear-gradient(to right bottom, #3c1448a5, #1a5875b0);
  color: #fff;
  border-radius: 5px;
  box-shadow: -10px 10px 30px #000000;
  backdrop-filter: blur(3px);
}

.privacy-tos-quick-anchor-box {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: fixed;
  left: 300px;
  bottom: 20px;
  padding-right: 10px;
  z-index: 1;
  transition: all 1s;
}

.privacy-tos-quick-anchor-btn {
  position: relative;
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 0 10px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
  font-size: 16px;
  opacity: 0.1;
  transition: all .5s;
}

.privacy-tos-quick-anchor-box.anchor-box-anim-1 {
  left: 70px;
  transition: all 1s;
}

.privacy-tos-quick-anchor-box.anchor-box-anim-2 {
  left: 0px;
  transition: all 1s;
}

.anchor-in {
  opacity: 1;
  transition: all 1.5s;
}

.privacy-tos-updated {
  width: 100%;
  margin-bottom: 15px;
}

.privacy-tos-para {
  margin: 0 8px 10px;
  padding: 0 8px 10px;
}

.privacy-tos-ul {
  width: 100%;
}

.privacy-tos-ul-no-dec {
  width: 100%;
  list-style-type: none;
}

.privacy-tos-ul-numbered {
  width: 100%;
  list-style-type: decimal;
}

.privacy-tos-li-hdr {
  width: 100%;
  font-weight: 600;
}

.privacy-tos-toc-links {
  color: #05f842;
  font-weight: 600;
  transition: all 0.5s ease-out;
  background: none;
  border: none;
  text-decoration: underline;
  text-align: start;
}

.privacy-tos-toc-links.privacy-tos-link-anim:hover {
  color: #fff;
  margin-left: 20px;
}

.privacy-tos-toc-links:hover {
  color: #fff;
}

.privacy-tos-collect-info-table {
  width: 95%;
  margin: 3px auto 10px;
}

.privacy-tos-collect-info-table tr th,
.privacy-tos-collect-info-table tr td {
  border: 1px solid #fff;
  padding: 2px 4px;
}
