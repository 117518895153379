.client-tasks-container {
    position: relative;
    display: flex;
    width: 99%;
    overflow: auto;
    margin: 1% auto;
    padding: 3px;
    flex-direction: column;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #000000;
    background-color: #cacaca;
    color: #fff;
    overflow: auto;
    max-height: 80dvh;
  }
  
  .client-tasks-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1180;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: space-evenly;
    background-color: #0000007d;
    backdrop-filter: blur(5px);
    animation: client-tasks-modal-fader 0.2s linear forwards;
  }

  @keyframes client-tasks-modal-fader {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }

  .client-tasks-modal-content {
    position: relative;
    color: #000000;
    background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
    padding: 2%;
    margin: auto 10px;
    z-index: 5;
    max-height: 95vh;
    height: fit-content;
    min-width: 45%;
    width: fit-content;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    border: 2px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 30px #000000;
    overflow: auto;
  }

  .client-tasks-modal-close-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: transparent;
    height: fit-content;
    color: #fff;
  }

  .client-tasks-modal-header {
    text-decoration: underline;
    white-space: nowrap;
    padding: 8px 10px;
    background: linear-gradient(to right bottom, #34113fcc, #2a8ebde2);
    color: #fff;
    text-shadow: -6px 6px 12px #000;
    border-radius: 6px;
    border: 1px solid #fff;
    width: fit-content;
    height: fit-content;
  }

  .client-tasks-modal-ul {
    color: #fff;
    width: 95%;
    list-style: none;
    padding: 0;
    margin: 5px auto;
  }

  .client-tasks-modal-mark-complete-box {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    
  }

  .client-tasks-info-display {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: fit-content;
    margin: 8px auto;
  }
  
  .client-tasks-none-display {
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: fit-content;
    margin: 8px auto 3px;
    padding: 3px 12px;
    background: linear-gradient(to right bottom, #3c1448f1, #1a3a75f3);
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: -2px 2px 10px #000000;
  }

  .client-tasks-start-button {
    color: #fff;
    z-index: 22;
    background: linear-gradient(to right bottom, #333333a4, #10085fd5);
    margin: 5px;
    padding: 2px 8px;
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: -2px 2px 4px #000;
    font-size: inherit;
    font-weight: inherit;
    width: fit-content;
    height: fit-content;
    white-space: nowrap;
  }
  
  .client-tasks-item-box {
    display: flex;
    width: 100%;
    color: #fff;
    height: fit-content;
    margin: 3px auto;
    padding: 1px 5px;
    align-items: center;
    background: linear-gradient(to right bottom, #3c1448f1, #1a3a75f3);
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: -2px 2px 10px #000000;
  }

  .client-tasks-item-inactive {
    background: linear-gradient(to right bottom, #454545f1, #212121);
  }

  .client-tasks-item-box-item-1 {
    display: flex;
    margin-right: auto;
    text-align: start;
  }
  
  .client-tasks-info-display ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%;
  }
  
  .client-tasks-circle {
    font-weight: bold;
    display: flex;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    margin: auto 5px;
  }
  
  .client-task-bullet-color-red {
    background-color: #f00000;
    color: #f00000;
  }
  
  .client-task-bullet-color-yellow {
    background-color: #e2e908;
    color: #e2e908;
  }
  
  .client-task-bullet-color-green {
    background-color: #05f842;
    color: #05f842;
  }
  
  .client-task-due-color-red {
    color: #f00000;
    margin-right: 5px;
  }
  
  .client-task-due-color-green {
    color: #05f842;
    margin-right: 5px;
  }
 
  .client-task-due-color-white {
    color: #fff;
    margin-right: 5px;
  }
  
  @media screen and (max-width: 900px) {
    .client-tasks-container {
      width: 95%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .client-tasks-item-box {
      font-size: 10px;
    }

    .client-tasks-modal-content {
      width: 95%;
    }
  }
  