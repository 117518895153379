.shop-inv-container {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 90%;
  margin: 1%;
  max-height: 95vh ;
  padding: 1%;
  flex-direction: column;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background-color: #cacaca;
}

.shop-content {
  max-height: 0px;
  width: 100%;
  transition: max-height 0.5s linear;
  overflow: auto;
 
}

.shop-content.show {
  max-height: 2200px;
  height: 100%;
  width: 100%;
  transition: max-height 0.5s linear;
}

.shop-inv-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 100%;
  position: relative;
}

.shop-inv-item-box {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  padding: 2px 4px;
  margin: 7px 0px;
  color: #fff;
  font-weight: 500;
  text-shadow: -2px 2px 4px #000;
  box-shadow: -2px 2px 5px #000;
  border: 1px solid #000;
  background: linear-gradient(to right bottom, #18516b, #8f8792);
  transition: all .4s linear;
}

.shop-inv-item-box.success {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  padding: 2px 4px;
  margin: 7px 0px;
  color: #05f842;
  font-weight: 700;
  text-shadow: -2px 2px 4px #05f842;
  box-shadow: -2px 2px 25px #05f842;
  border: 1px solid #05f842;
  background: linear-gradient(to right bottom, #18516b, #8f8792);
  transition: all .4s linear;
}

.shop-item-name {
  width: fit-content;
  min-width: 100%;
}

.shop-item-name-confirm {
  width: fit-content;
}

.item-name-delete {
  display: flex;
  flex-direction: column;
  min-width: 30%;
}

.item-name-delete-confirm {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.item-name-delete-btn {
  width: fit-content;
  padding: 2px;
  border: none;
  background: none;
  color: #fff;
}

.item-name-delete-btn.confirm {
  display: flex;
  width: fit-content;
  margin: 2px 4px;
  padding: 2px 5px;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
  background: #ffffff;
  color: #000000;
  border: 2px solid #000000;
  border-radius: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.shop-item-btn {
  display: flex;
  min-width: 15%;
  width: fit-content;
  padding: 2px 5px;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  background: #4d4d4d;
  color: #fff;
  border: 2px solid #fff;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.shop-quantity-max-btn:hover {
  color: #05f842;
  border: 2px solid #05f842;
}

@media screen and (max-width: 900px) {
  .shop-inv-container {
    width: 90%;
  }
  .item-name-delete {
    min-width: 35%;
  }
}

@media screen and (max-width: 600px) {
  .shop-inv-container  {
    max-height: 70vh;
  }

  .shop-inv-item-box {
    font-size: 12px;
  }
  .item-name-delete {
    min-width: 35%;
  }
  .shop-item-btn {
    min-width: 12%;
    display: flex;
    flex-direction: column;
    padding: 2px;
  }
}
