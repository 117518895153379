.client-pics-container {
    position: relative;
    display: flex;
    width: 99%;
    margin: 1% auto;
    padding: 3px;
    flex-direction: column;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #000000;
    background-color: #cacaca;
    color: #fff;
    overflow: auto;
    max-height: 80dvh;
  }
  
  .client-pics-none-display {
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: fit-content;
    margin: 8px auto 3px;
    padding: 3px 12px;
    background: linear-gradient(to right bottom, #3c1448f1, #1a3a75f3);
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: -2px 2px 10px #000000;
  }
  
  .client-pics-loading-box {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px auto 3px;
    padding: 3px 12px;
    background: linear-gradient(to right bottom, #3c1448f1, #1a3a75f3);
    color: #fff;
  }
  
  .client-pics-copy-btn {
    background: linear-gradient(to right bottom, #05f842c8, #1a3a75);
    font-weight: 800;
    color: inherit;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 0px 4px 2px;
    margin: 2px 10px 2px 0;
    height: fit-content;
    width: fit-content;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    font-size: 26px;
    position: absolute;
    top: 6px;
    left: 40px;
  }
  
  .client-pics-images-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 1%;
    margin: 10px auto;
    box-shadow: -3px 3px 8px #000;
    border-radius: 8px;
    border: 1px solid #fff;
    background: linear-gradient(to right bottom, #3c1448f1, #1a3a75f3);
  }
  
  .client-pics-img-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    margin: 0;
    padding: 0;
    position: relative;
    max-width: 30%;
    max-height: 70vh;
  }
  
  .client-pics-img {
    width: auto;
    height: auto;
    max-width: 70%;
    max-height: 70%;
    border: 2px #fff solid;
    border-radius: 5px;
    box-shadow: -5px 5px 10px #000000;
    margin: 5px;
  }
  
  .client-pics-upload-box {
    display: flex;
    flex-direction: column;
    margin: 5px;
    box-shadow: -3px 3px 8px #000;
    border-radius: 8px;
    border: 1px solid #fff;
    background: linear-gradient(to right bottom, #3c1448f1, #1a3a75f3);
  }
  
  .client-pics-upload-btn {
    width: fit-content;
    padding: 3px;
    background: linear-gradient(to right top, #747474, #4a4a4a);
    box-shadow: 0px 0px 20px #18296b;
    color: #fff;
    border: 2px solid #fff;
    margin: 5px 5px 5px 15px;
  }
  
  .client-pics-uploaded-img {
    border: 2px solid #fff;
    border-radius: 4px;
    box-shadow: -3px 3px 8px #000;
    margin: 10px;
    max-width: 45%;
    width: auto;
  }
  
  .client-pics-uploaded-images-box {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-around;
  }

  .email-item.client-read-email {
    background-color: #424242;
  }
  
  @media screen and (max-width: 900px) {
    .client-pics-container {
      width: 95%;
    }
  
    .client-pics-copy-btn {
      background: linear-gradient(to right bottom, #05f842c8, #1a3a75);
      font-weight: 800;
      color: inherit;
      border: 1px solid #fff;
      border-radius: 4px;
      padding: 0px 4px 2px;
      margin: 2px 10px 2px 0;
      height: fit-content;
      width: fit-content;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      font-size: 12px;
      position: absolute;
      top: 6px;
      left: 15px;
    }
  }
  
  @media screen and (max-width: 600px) {
  }
  