.client-search-container {
    display: flex;
    justify-content: center;
    color: #fff;
}

.client-search-over-div {
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color: #fff;
    color: #000;
    padding: 3px 8px 4px;
    min-width: 70%;
}

.client-search-result-item {
    display: flex;
    margin: 1px;
    padding: 1px;
    border: none;
    background: inherit;
    border-bottom: 1px solid #000;
    color: #000;
}

.client-search-result-item :first-child {
    font-weight: 600;
    padding: 1px 5px 1px 3px;
}

.client-search-result-item :nth-child(2) {
    font-weight: 400;
    padding: 1px 3px 1px 5px;
    margin-left: auto;
}

span.client-search-number-split :first-child{
    font-weight: 500;
    padding: 0;
}

span.client-search-number-split :nth-child(2){
    font-weight: 300;
    padding: 0;
}