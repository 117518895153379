.eml-preview-container {
  width: 100% !important;
  height: fit-content !important;
  overflow: hidden !important;
}

.eml-preview-box {
  height: fit-content;
  width: 100%;
  overflow: hidden;
  margin-top: 5px;
}

.eml-preview-hdr-box {
  height: fit-content !important;
  width: 90% !important;
  background-color: #1a3a75 !important;
  background: linear-gradient(to right bottom, #18516b, #81608d) !important;
  margin: 0px auto !important;
  border-radius: 5px !important;
  padding: 10px !important;
}

.eml-preview-hdr-img {
  margin: 5px 10px !important;
  background-color: #fdfdfd;
  background: linear-gradient(to bottom, #fdfdfd, #fdfdfd);
  border-radius: 5px !important;
  border: 2px solid #000 !important;
  box-shadow: -3px 3px 6px #000 !important;
}

.eml-preview-hdr-bg {
  color: #fff;
}

.eml-preview-hdr {
  margin: 5px 10px !important;
  padding: 0 !important;
  text-shadow: -3px 3px 6px #000 !important;
  font-size: 28px !important;
}

.eml-preview-text-box {
  font-size: 14px !important;
  padding: 10px !important;
}

.eml-preview-hor-line {
  background-color: #000;
  height: 1px;
  width: 95%;
  margin: auto;
}

.eml-preview-unsub {
  color: #fff;
  width: 100%;
  text-align: center;
}

.eml-preview-links {
  color: #ffffff !important;
  background-color: #1a5875 !important;
  text-decoration: none !important;
  padding: 12px 20px !important;
  margin: 2px 5px !important;
  font-size: 18px !important;
  border-radius: 3px !important;
}

.eml-preview-links-box {
  width: 90% !important;
  height: fit-content;
  text-align: center;
  margin: 5px auto 40px !important;
}

.eml-preview-anchor {
    border: none;
    border-bottom: 1px solid #397cf8;
    background: none;
    color: #397cf8;
}

.eml-preview-anchor:hover {
    border-bottom: 1px solid #1c54be;
    color: #1c54be;
}
