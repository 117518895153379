.msg-img-shuffle-box {
  display: flex;
  flex-direction: column;
  margin: 3px auto;
  height: 240px;
  width: 100%;
  min-width: 250px;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.msg-img-shuffle-controller-box {
  display: flex;
  width: 60%;
  justify-content: center;
  margin: auto auto 2px;
  align-items: center;
  color: #fff;
  background: #11085f65;
  z-index: 15;
  backdrop-filter: blur(5px);
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.msg-img-shuffle-btn-copy {
  color: #fff;
  background: linear-gradient(to right bottom, #05f842c8, #1a3a75);
  margin: 2px 10px;
  padding: 2px 8px;
  font-size: 12px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
}

.msg-img-shuffle-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 4px;
  font-size: 12px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
}

.msg-img-shuffle-btn-icon {
  color: #fff;
}

.msg-img-shuffle-img {
  max-width: 90%;
  max-height: 150px;
  border: 10px solid #fff;
  border-radius: 8px;
  transition: all 0.4s ease-in-out;
  position: absolute;
  opacity: 0.8;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.msg-img-shuffle-img.msg-img-shuffle-img-selected {
  opacity: 1;
  z-index: 10;
  box-shadow: 0px 0px 15px #05b531c8;
  border: 1px solid #fff;
  transform: translate(-50%, -60%);
}

/* left of center of shuffle */
.msg-img-shuffle-img.img-pos-1l {
  transition: all 0.4s ease-in-out;
  transform: rotate(-4deg) translate(-56%, -65%);
  z-index: 9;
  box-shadow: 4px -4px 7px #000000;
}

.msg-img-shuffle-img.img-pos-2l {
  transition: all 0.4s ease-in-out;
  transform: rotate(-6deg) translate(-58%, -59%);
  z-index: 8;
  box-shadow: 4px -4px 7px #000000;
}

.msg-img-shuffle-img.img-pos-3l {
  transition: all 0.4s ease-in-out;
  transform: rotate(-8deg) translate(-62%, -75%);
  z-index: 7;
  box-shadow: -4px -4px 7px #000000;
}

/* right of center of shuffle */
.msg-img-shuffle-img.img-pos-1r {
  transition: all 0.4s ease-in-out;
  transform: rotate(4deg) translate(-38%, -57%);
  z-index: 9;
  box-shadow: 4px -4px 7px #000000;
}

.msg-img-shuffle-img.img-pos-2r {
  transition: all 0.4s ease-in-out;
  transform: rotate(6deg) translate(-36%, -50%);
  z-index: 8;
  box-shadow: 4px -4px 7px #000000;
}

.msg-img-shuffle-img.img-pos-3r {
  transition: all 0.4s ease-in-out;
  transform: rotate(8deg) translate(-35%, -60%);
  z-index: 7;
  box-shadow: 4px -4px 7px #000000;
}

@media screen and (max-width: 600px) {
  .msg-img-shuffle-box {
    display: flex;
    flex-direction: column;
    margin: 3px auto;
    height: 250px;
    width: 100%;
    z-index: 1;
    position: relative;
    overflow: hidden;
  }

  .msg-img-shuffle-img {
    max-width: 90%;
    max-height: 170px;
    border: 10px solid #fff;
    border-radius: 8px;
    transition: all 0.4s ease-in-out;
    position: absolute;
    opacity: 0.8;
    top: 50%;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}
