.metrics-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  overflow: auto;
}

.metrics-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
}

.metrics-box {
  display: flex;
  flex-direction: column;
  color: #fff;
  margin: 10px 0px;
  padding: 15px;
  width: 45%;
  height: fit-content;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: -4px 4px 15px #000000;
  background: linear-gradient(to right bottom, #3c144884, #1a5875cd);
  backdrop-filter: blur(4px);
}

.metrics-ul-container {
  display: flex;
  margin: 10px auto;
  justify-content: space-around;
  width: 95%;  
}

.metrics-ul-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;  
}

.metrics-ul {
  list-style-type: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.metrics-box-hdr {
  text-align: center;
  text-shadow: -3px 3px 8px #000;
}


@media screen and (max-width: 900px) {
  .metrics-box {
    width: 90%;
  }
}
