.key-shortcuts-box {
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.key-shortcuts-menu-box {
  display: flex;
  flex-direction: column;
  max-height: 60dvh;
  width: fit-content;
  overflow: auto;
  padding: 3px;
  background: linear-gradient(to right bottom, #333333cd, #11085f9e);
  backdrop-filter: blur(5px);
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -4px 4px 6px #000;
}

.key-shortcuts-menu-item {
  display: flex;
  width: fit-content;
  margin: 2px auto;
  padding: 3px;
  border-bottom: 1px solid #000;
}

.key-shortcuts-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #333333a4, #10085fd5);
  margin: 5px;
  padding: 2px 14px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  font-size: 14px;
  font-weight: 600;
  width: fit-content;
  height: fit-content;
}
