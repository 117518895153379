.email-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1110;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #0000007d;
  backdrop-filter: blur(3px);
  animation: email-modal-fader 0.2s linear forwards;
}

@keyframes email-modal-fader {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.email-modal-content {
  position: relative;
  color: #fff;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
  width: 85%;
  padding: 10px;
  z-index: 15;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 30px #000000;
  max-height: 90vh;
  overflow: auto;
}

.email-modal-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.email-modal-list-hdr-bg {
  background: linear-gradient(to right bottom, #143064f1, #05f842ad);
}

.email-modal-list li {
  background-color: #525151;
  color: #fff;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  margin: 15px auto;
  padding: 10px;
  border: 1px solid #bdbbbb;
  border-radius: 5px;
  box-shadow: -3px 3px 6px #000000;
  overflow-wrap: anywhere;
}

.email-modal-list-older {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.email-modal-list-older li {
  background-color: #525151;
  color: #fff;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  margin: 15px auto;
  padding: 10px;
  border: 1px solid #bdbbbb;
  border-radius: 5px;
  box-shadow: -3px 3px 6px #000000;
  overflow-wrap: anywhere;
}

.email-modal-list-older-hdr {
  position: sticky;
  top: 0px;
  font-size: 12px;
}

.email-modal-body-html {
  font-size: 13px;
}

.email-modal-close {
  z-index: 50;
  width: fit-content;
  color: #fff;
  margin: 1px 5px;
  padding: 1px 7px;
  position: sticky;
  top: 0;
  right: 0;
  align-self: flex-end;
  background: #00000056;
  border: 1px #fff solid;
  border-radius: 20px;
  backdrop-filter: blur(2px);
}

.email-modal-images-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  height: fit-content;
  position: sticky;
  bottom: 1px;
}

.email-modal-images-box {
  width: 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  margin: 4px;
  background: linear-gradient(to right bottom, #929292bf, #1a3a75e7);
  box-shadow: -1px 1px 3px #000;
  border-radius: 8px;
  border: 1px solid #fff;
}

.email-modal-pdf-box {
  width: fit-content;
  max-width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: linear-gradient(to right bottom, #929292bf, #1a3a75e7);
  box-shadow: -1px 1px 3px #000;
  border-radius: 8px;
  border: 1px solid #fff;
  margin: 5px;
}

.email-modal-pdf-file {
  display: flex;
  height: fit-content;
  width: 98%;
  flex-wrap: wrap;
  margin: 5px;
  justify-content: center;
  padding: 1%;
  box-shadow: -3px 3px 8px #000;
  border-radius: 4px;
  border: 1px solid #060076;
}

.email-modal-older-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
  position: sticky;
  bottom: 0px;
  text-decoration: none;
  z-index: 2;
}

.email-modal-button {
  color: #fff;
  z-index: 22;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
}

.page-header.email-modal-img-hdr {
  width: 90%;
  margin: 15px auto;
  padding: 8px;
}

.email-modal-img-hdr-box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.email-modal-img {
  width: auto;
  max-width: 95%;
  max-height: 70vh;
  height: auto;
  border: 1px #fff solid;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000000;
  margin: 5px;
}

.email-modal-copy-btn {
  background: linear-gradient(to right bottom, #05f842c8, #1a3a75);
  color: inherit;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 0px 2px;
  height: fit-content;
  width: fit-content;
  font-size: 10px;
  cursor: pointer;
  outline: inherit;
}

.email-modal-img-overlay-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: fit-content;
  margin: 0;
  padding: 0;
  position: relative;
}

.email-modal-action-img-button {
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  color: #fff;
  background: linear-gradient(to right bottom, #3333337d, #10085fd5);
  border: 1px solid #000;
  border-radius: 100%;
  cursor: inherit;
  padding: 2px;
  margin: 1%;
  font-size: 12px;
}

@media screen and (max-width: 900px) {
  .email-modal-img-hdr-box {
    justify-content: space-between;
  }

  .email-modal-images-box .email-modal-img-hdr-box h5.page-header {
    padding: 8px;
  }
}

@media screen and (max-width: 600px) {
  .email-modal-container {
    align-items: flex-start;
    margin-top: 3dvh;
  }

  .email-modal-images-container {
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  .email-modal-img-hdr-box {
    justify-content: space-evenly;
  }

  .email-modal-images-box {
    width: 18%;
    padding: 5px;
  }

  .email-modal-content {
    width: 90%;
    height: fit-content;
    max-height: 94dvh;
  }
}
