.send-email-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1180;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: space-evenly;
  background-color: #0000007d;
  backdrop-filter: blur(5px);
  animation: send-email-modal-fader 0.4s linear forwards;
}

.send-email-greater-900 {
  display:flex;
}

.send-email-less-900 {
  display:none;
}

@keyframes send-email-modal-fader {
  0% {
    opacity: 0.1;
    transform: scale(.01);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.send-email-modal-content {
  position: relative;
  color: #fff;
  background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
  padding: 2%;
  margin: auto 10px;
  z-index: 5;
  max-height: 95vh;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 30px #000000;
  overflow: hidden;
}

.send-email-input-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  overflow: visible;
  overflow-x: hidden;
}

.send-email-header-temp-box {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.send-email-modal-header {
  text-decoration: underline;
  white-space: nowrap;
  padding: 8px 10px;
  background: linear-gradient(to right bottom, #34113fcc, #2a8ebde2);
  color: #fff;
  text-shadow: -6px 6px 12px #000;
  border-radius: 6px;
  border: 1px solid #fff;
  width: fit-content;
  height: fit-content;
}

.send-email-start-button {
  color: #fff;
  z-index: 22;
  background: linear-gradient(to right bottom, #333333a4, #10085fd5);
  margin: 5px;
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  font-size: inherit;
  font-weight: inherit;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
}

.send-email-send-btn {
  color: #fff;
  z-index: 22;
  background: linear-gradient(to right bottom, #333333a4, #10085fd5);
  margin: 4px 10px;
  padding: 2px 14px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  font-size: 18px;
  font-weight: 600;
  width: fit-content;
  height: fit-content;
}

.send-email-img-button {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
  color: #fff;
  background: none;
  border: none;
  cursor: inherit;
}

.send-email-modal-close-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background: transparent;
  height: fit-content;
}

.send-email-modal-close {
  z-index: 190;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 10px;
  margin: 2px 5px;
  position: sticky;
  top: 0;
  right: 3%;
  align-self: flex-end;
  background: #00000056;
  border: 1px #fff solid;
  border-radius: 50%;
  backdrop-filter: blur(2px);
}

.send-email-modal-attach-clear {
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 30px;
  padding: 10px;
  color: #fff;
  margin: 2px 15px;
  align-self: flex-end;
  background: #00000056;
  border: 1px #fff solid;
  border-radius: 5px;
  backdrop-filter: blur(2px);
}

.send-email-modal-close-x::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.send-email-modal-close-x {
  line-height: 30px;
  color: #05F842;
}

.send-email-options-box {
  display: flex;
  flex-wrap: wrap;
  min-width: 80%;
  margin: 5px auto;
  justify-content: flex-end;
  align-items: center;
  height: fit-content;
}

.send-email-icon-button {
  color: #fff;
  background: none;
  border: none;
  height: fit-content;
  width: fit-content;
  padding: 0px;
  cursor: pointer;
}

.send-email-preview-cont {
  width: 49%;
  margin: 0;
  padding: 0;
  height: fit-content;
  flex-direction: column;
  align-items: center;
}

.send-email-preview-box {
  width: 93%;
  color: #fff;
  min-height: 83vh;
  height: 70vh;
  margin: 10px auto;
  padding: 3%;
  background-color: #4a4a4a;
  overflow: auto;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -5px 5px 12px #000;
}

.send-email-quote-link-box {
  display: flex;
  width: 90%;
  align-items: center;
}

.send-email-quote-link-field {
  display: flex;
  width: 70%;
}

.send-email-add-quote-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #333333a4, #10085fd5);
  margin: 0px auto;
  padding: 2px 14px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  width: fit-content;
  height: fit-content;
}

.send-email-error-ul {
  margin: 10px auto;
}

@media screen and (max-width: 900px) {
  .send-email-greater-900 {
    display:none;
  }

  .send-email-less-900 {
    display:flex;
  }

  .send-email-preview-cont {
    width: 95%;
    margin: 0;
    padding: 0;
    height: fit-content;
    flex-direction: column;
    align-items: center;
  }

  /* div .send-email-modal-content {
    width: 95%;
  } */
}

@media screen and (max-width: 600px) {
  .send-email-modal-content {
    width: 93%;
  }

  .send-email-header-temp-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .send-email-modal-header {
    font-size: 26px;
    margin: 5px;
    width: 100%;
    text-align: center;
    border-radius: 0px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    border-left: none;
    border-right: none;
    padding: none;
    margin: none;
  }
}
