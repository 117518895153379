.open-collapse {
    height: 20px;
    width: 20px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
  }
  
  .collapse-bar-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    display: block;
    height: 2px;
    width: 100%;
    background: linear-gradient(to left, #ffffff, #ffffff);
    transition: transform 1s linear;
  }
  
  .collapse-bar-1.collapsed {
    animation: rotate1Plus .3s linear forwards;
  }
  
  .collapse-bar-1.not-collapsed {
    animation: rotate1Minus .3s linear forwards;
  }
  
  @keyframes rotate1Plus {
    0% {background: #f80505; transform:translate(-50%, -50%) rotate(360deg);}
    30% {background: #f80505; transform:translate(50%, -300%) rotate(270deg);}
    60% {background: #f80505; transform:translate(50%, -300%) rotate(180deg);}
    100% {background: linear-gradient(to left, #ffffff, #ffffff); transform:translate(-50%, -50%) rotate(90deg);}
  }
  
  @keyframes rotate1Minus {
    0% {background: #05F842; transform:translate(-50%, -50%) rotate(90deg);}
    30% {background: #05F842; transform:translate(50%, -300%) rotate(180deg);}
    60% {background: #05F842; transform:translate(50%, -300%) rotate(270deg);}
    100% {background: linear-gradient(to left, #ffffff, #ffffff); transform:translate(-50%, -50%) rotate(360deg);}
  }
  
  .collapse-bar-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    display: block;
    height: 2px;
    width: 100%;
    background: linear-gradient(to left, #ffffff, #ffffff);
    transition: transform 1s linear;
  }
  
  .collapse-bar-2.collapsed {
    animation: rotate2Plus .3s linear forwards;
  }
  
  .collapse-bar-2.not-collapsed {
    animation: rotate2Minus .3s linear forwards;
  }
  
  @keyframes rotate2Plus {
    0% {background: #f80505; transform:translate(-50%, -50%) rotate(360deg);}
    30% {background: #f80505;  transform: translate(-150%, -300%) rotate(240deg);}
    60% {background: #f80505; transform: translate(-150%, -300%) rotate(120deg);}
    100% {background: linear-gradient(to left, #ffffff, #ffffff); transform:translate(-50%, -50%) rotate(0deg);}
  }
  
  @keyframes rotate2Minus {
    0% {background: #05F842; transform:translate(-50%, -50%) rotate(0deg);}
    30% {background: #05F842; transform:translate(-200%, -300%) rotate(120deg);}
    60% {background: #05F842; transform:translate(-200%, -300%) rotate(240deg);}
    100% {background: linear-gradient(to left, #ffffff, #ffffff); transform:translate(-50%, -50%) rotate(360deg);}
  }