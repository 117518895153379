.clients-page {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }

.clients-page-container {
    display: flex;
    color: #fff;
    width: 95%;
    height: fit-content;
    margin: 1% auto;
    padding: 1%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 2px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 30px #000000;
    background: linear-gradient(to right bottom, #3c1448bf, #1a3a75c5);
  }

  .clients-page-recent-box {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .clients-page-recent-header {
    display: flex;
    width: 98%;
    height: fit-content;
    justify-content: space-between;
    padding: 3px;
  }
  
  .clients-page-recent-refresh-button {
    width: fit-content;
    height: fit-content;
    color: #fff;
    background: none;
    border: none;
    cursor: inherit;
  }
  
  .clients-page-recent-box div h3{
    width: 100%;
    text-align: center;
    margin: 5px 0 15px;
  }

  .clients-page-recent-item {
    width: 48%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 1%;
    margin: 1%;
    border: 2px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 30px #000000;
    background: linear-gradient(to left bottom, #383838, #10085fd5);
  }

  .clients-page-recent-item-status {
    display: flex;
    height: fit-content;
    align-items: center;
  }

  .clients-page-small-circle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin: 0px 5px;
    display: inline-block;
  }
  
  .clients-page-small-circle.lead {
    background-color: #c26a0d;
  }
  
  .clients-page-small-circle.client {
    background-color: #05F842;
  }

  .clients-page-small-circle.other {
    background-color: #dada10;
  }

  .clients-page-client-anchor{
    margin-left: 6px;
    color: #fff;
    text-decoration: none;
    background: linear-gradient(to right bottom, #3333337d, #10085fd5);
    padding: 2px 8px;
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: -2px 2px 4px #000;
    width: fit-content;
    height: fit-content;
  }

  .clients-page-client-anchor:link {
    color: #05F842;
  }

  .clients-page-client-anchor:hover {
    color: #fff;
  }
  
  .clients-page-client-anchor:visited {
    color: #1485e1;
  }
  
  .clients-page-client-anchor:visited:hover {
    color: #fff;
  }

  .clients-search-field-box {
    display: flex;
    width: 70%;
  }

  @media screen and (max-width: 900px) {
    .clients-page-recent-item {
      width: 97%;
    }
  }
 
  @media screen and (max-width: 600px) {
    .clients-page-recent-item {
      width: 97%;
    }

    .clients-search-field-box {
      display: flex;
      width: 100%;
    }
  }