.client-notes-container {
  position: relative;
  display: flex;
  width: 99%;
  overflow: auto;
  margin: 1% auto;
  padding: 3px;
  flex-direction: column;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background-color: #cacaca;
  color: #fff;
  overflow: auto;
  max-height: 80dvh;
}

.client-notes-box {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 3px;
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #000000;
  background: linear-gradient(to right bottom, #18516b, #8f8792);
}

.client-note-item {
  width: 99%;
  height: fit-content;
  padding: 5px;
  margin: 3px auto;
  border: 1px solid #fff;
  border-radius: 5px;
  margin: 7px 2px;
  box-shadow: -3px 3px 6px #000000;
  cursor: text;
  background: linear-gradient(to right bottom, #3c1448f1, #1a3a75f3);
  overflow-wrap: break-word;
}

.client-note-hdr-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2px;
  height: fit-content;
}

.client-note-func-btn {
  display: flex;
  width: fit-content;
  align-items: center;
  margin: 2px;
  padding: 2px 4px;
  height: fit-content;
  background: #ffffff;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: -2px 2px 5px #000;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.client-note-delete-confirm {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.client-note-delete-confirm-text {
  width: fit-content;
}

.client-note-func-btn.confirm {
  width: fit-content;
  margin: 2px 8px;
}

.client-note-new-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: fit-content;
  padding: 15px;
  margin: 3px auto;
  border: 1px solid #fff;
  border-radius: 5px;
  background: linear-gradient(to right bottom, #3c1448f1, #1a3a75f3);
}

.client-note-hr {
  width: 100%;
  margin: 2px auto 8px;
  padding: 0;
  height: 1px;
  background-color: #fff;
}

.client-note-par {
  width: 100%;
  margin: 2px auto;
  padding: 0 6px;
  height: fit-content;
  text-wrap: wrap;
  white-space: pre-wrap;
}

.client-note-par-small {
  font-size: 12px;
}

.client-note-par-md {
  font-size: 14px;
}

.client-note-btn-box {
  display: flex;
  width: fit-content;
  height: fit-content;
  margin: 5px 3px;
  justify-content: flex-end;
  align-items: center;
}

.client-note-title {
  font-weight: 700;
  font-size: 14px;
}

.client-note-new {
  display: flex;
  width: 100%;
  margin: 5px auto;
  max-height: 0px;
  opacity: 0.01;
  transition: all 0.6s linear;
  overflow: hidden;
}

.client-note-new.show {
  max-height: 600px;
  height: fit-content;
  opacity: 1;
  transition: all 0.6s linear;
}

.client-note-btn {
  color: #fff;
  background: linear-gradient(to right bottom, #333333a4, #10085fd5);
  margin: 5px;
  padding: 2px 14px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  font-size: 14px;
  font-weight: 600;
  width: fit-content;
  height: fit-content;
}

.client-note-info-box {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right bottom, #333333, #11085f);
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: -2px 2px 4px #000;
  margin: 4px;
  padding: 4px 8px 2px 2px;
}

.client-note-info-btn-box {
  display: flex;
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  padding: 0;
  justify-content: space-between;
  align-items: center;
}

.client-note-info-btn-box div {
  display: flex;
  align-items: center;
}

.client-note-img-btn {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
  color: #fff;
  background: none;
  border: none;
  cursor: inherit;
}

.client-note-shortcut-note {
  width: 100%;
  color: #000;
  font-size: 12px;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .client-notes-container {
    width: 95%;
  }

  .client-note-par-small {
    font-size: 10px;
  }

  .client-note-par-md {
    font-size: 13px;
  }

  .client-note-hdr-box {
    flex-direction: row;
  }

  .client-note-shortcut-note {
    width: 100%;
    color: #000;
    font-size: 11px;
    text-align: center;
  }
}
