span.carousel-control-next-icon,
span.carousel-control-prev-icon {
  background-color: #000000ce;
  padding: 15px;
  border: 10px solid #00000000;
  border-radius: 100%;
  width: 20%;
}

a.carousel-control-next,
a.carousel-control-prev {
  opacity: 0.8;
  top: 70%;
}

.img-modal-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 98vh;
  height: fit-content;
  width: fit-content;
  background: linear-gradient(to left top, #34113f, #2a8fbd);
  border: 3px solid #fff;
  color: #fff;
  border-radius: 8px;
  box-shadow: -10px 10px 30px #000;
}

.img-modal-full-box {
    display: flex;
}

.img-modal-img {
  height: 60vh;
  width: auto;
  max-width: 40vw;
  margin: 8px;
  border: 4px solid #ffffff;
  border-radius: 3px;
  box-shadow: -5px 5px 10px #000000;
}

.img-modal-finishers-ul {
    color: #fff;
    font-weight: 600;
    height: fit-content;
    min-width: 300px;
    width: fit-content;
    list-style: none;
    margin: 5px;
    padding: 10px 20px;
    line-height: 1.6rem;
    background: linear-gradient(to right bottom, #18516b, #8f8792);
    border: 3px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #000000;
  }

@media screen and (max-width: 600px) {
  .img-modal-container {
    max-height: 80vh;
    height: fit-content;
    width: 80vw;
    overflow: hidden;
    flex-direction: column;
  }

  .img-modal-img {
    height: 50vh;
    max-width: 90%;
    margin: 20px 10px;
  }

  a.carousel-control-next {
    right: 3%;
  }

  a.carousel-control-prev {
    left: 3%;
  }
}
